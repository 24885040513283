import * as Bend from '../../lib/bend-1.1.8'
import { getActiveUser } from '../../helpers'

enum Tables {
  ACTION = 'action',
  ACTIVITY = 'activity',
  CATEGORY = 'category',
  CATEGORY_GROUP = 'categoryGroup',
  CERTIFICATION = 'certification',
  CHALLENGE = 'challenge',
  CHART = 'chart',
  COMMENT = 'comment',
  COMMUNITY = 'community',
  CUSTOM_ACTIVITY = 'customActivity',
  EVENT = 'event',
  EVENT_TEMPLATE = 'eventTemplate',
  PLACE = 'business',
  POLL_QUESTION = 'pollQuestion',
  POLL_QUESTION_ANSWER = 'pollQuestionAnswer',
  PUSH_NOTIFICATION = 'push',
  PUSH_CONFIG = 'pushConfig',
  CLIENT_APP_CONFIG = 'clientAppConfig',
  PUSH_TEMPLATE = 'pushTemplate',
  SPRINT = 'sprint',
  SEGMENT_TYPE = 'segmentType',
  SEGMENT = 'segment',
  SURVEY = 'survey',
  SURVEY_RESPONSE = 'surveyResponse',
  STUDIO_APP = 'studioApp',
  TEAM = 'team',
  USER = 'user',
  USER_ADDRESS = 'userAddress',
  VOLUNTEERING = 'volunteer_opportunity',
  WHITELABEL = 'whitelabel',
  ORGANIZATIONS = 'organizations',
  SCHOOLS = 'schools',
  GROUPS = 'groups',
  GOALS = 'goals',
  GOALS_STATUS = 'goalsStatus'
}

class BendTable {
  public Tables = Tables
  // public user = getActiveUser()
  private bend: any
  private CREDENTIALS: {
    APP_KEY: string,
    APP_SECRET: string,
    APP_ADMIN_GROUP: string,
    APP_URL: string
  }
  private user: {
    community: {
      id: string
      admin: boolean
    }
  }
  private defaultSettings: { // should be defining this elsewhere and importing the same one into helpers
    searchTerm: string
    categoryId: string
    category: {
      _id: string
      id: string
      type: string
      name: string
      groups: {}
    }
    collectionId: string
    communityId: string
    survey: boolean
    itemsPerPage: number
    currentPage: number
    sortDirection: {
      key: string
    },
    sort: any,
    community: { _id: string },
    group: any,
    team: any,
    teams: Array<string>,
    notYetExported: boolean,
    segments: Array<string>,
    segmentTypes: Array<string>,
    email: string,
    pollQuestionId: string,
    userId: string,
    surveyId: string,
    version: number | null,
    enabled: boolean | null
    goals: Array<string>,
    goalsStatus:Array<String>
  }

  constructor () {
    this.CREDENTIALS = {
      APP_KEY: '589d36e94bad3014f50128ce',
      APP_SECRET: 'deduKe8DAuA1ry2cYYQXSQEFHgZy9qTvrL0D2lsc',
      APP_ADMIN_GROUP: 'admin',
      APP_URL: 'https://api.bend.io/group/' // XXX APP_URL name isn't accurate
    }
    this.bend = Bend
    this.user = {
      community: {
        id: '',
        admin: false
      }
    }
    this.defaultSettings = {
      searchTerm: '',
      categoryId: '',
      category: {
        _id: '',
        id: '',
        type: '',
        name: '',
        groups: {}
      },
      collectionId: '',
      communityId: '',
      itemsPerPage: 100,
      currentPage: 1,
      sortDirection: { // where does direction go here? maybe its just one or the other in the controller, i think that makes sense
        key: ''
      },
      sort: {}, // not sure which way i decided to do this, sort.direction and sort.key
      survey: false,
      community: { _id: '' },
      group: {},
      team: {},
      teams: [],
      segments: [],
      segmentTypes: [],
      notYetExported: false,
      email: '',
      pollQuestionId: '',
      userId: '',
      surveyId: '',
      version: null,
      enabled: null,
      goals: [],
      goalsStatus: []
    }
  }

  // master generic db functions
  public get (table: string, id: string, params = {}) { // get one
    if (table === 'user') return this.bend.User.get(id, params)
    else return this.bend.DataStore.get(table, id, params)
  }

  public async getList (table: string, settings = this.defaultSettings, params = {}) {
    let query = await this.listQuery(settings)
    if (table === 'studioApp') {
      return this.bend.DataStore.find(table, query)
    }
    if (table === 'user') return this.bend.User.find(query, params)    
    else return this.bend.DataStore.find(table, query, params)
  }

  public async getPagedList (table: string, settings = this.defaultSettings, params = {}) {
    let query = this.paginationQuery(settings)
    if (table === 'user') return this.bend.User.find(query, params)
    else return this.bend.DataStore.find(table, query, params)
  }

  public async count (table: string, settings = this.defaultSettings) {
    const query = await this.listQuery(settings)
    if (table === 'user') return this.bend.User.count(query)
    else return this.bend.DataStore.count(table, query)
  }

  public async pagedCount (table: string, settings = this.defaultSettings) {
    const query = await this.paginationQuery(settings)
    if (table === 'user') return this.bend.User.count(query)
    else return this.bend.DataStore.count(table, query)
  }

  // i know create() and update() here hit the same code. previously the calls were seperated though i don't see a point in doing that
  // i think i did have trouble with this previously -- see commented code in update()
  public create (table: string, data: any) {
    let communityAdmin = getActiveUser().communityAdmin
    if (table === 'user') {
      return this.bend.execute('createUser', data)
    }
    if (communityAdmin) {
      return this.bend.execute('saveDataForCommunityAdmin', {
        type: table,
        data: data
      })
    } else {
      return this.bend.DataStore.save(table, data)
    }
  }

  // To update make sure data has an _id (To see why go to bend-1.1.8.js line 2250
  public update (table: string, data: any) {
    let communityAdmin = getActiveUser().communityAdmin
    if (communityAdmin) {
      const packet = {
        type: table,
        data
      }
      return this.bend.execute('saveDataForCommunityAdmin', packet)
    } else return this.bend.DataStore.save(table, data)
    // if (data._id) return this.bend.DataStore.update(table, data)
    // return this.bend.DataStore.save(table, data)
  }

  public async delete (table: string, id: string) {
    let obj = await this.get(table, id)
    obj.deleted = true
    return this.update(table, obj)
  }

  public async listQuery (settings = this.defaultSettings) {
    let user = await getActiveUser()
    let q = new this.bend.Query()
    let {
      communityId,
      community,
      category,
      searchTerm,
      categoryId,
      collectionId,
      survey,
      group,
      team,
      teams,
      notYetExported,
      segments,
      segmentTypes,
      email,
      pollQuestionId,
      version,
      userId,
      surveyId,
      enabled,
      goals,
      goalsStatus
      
    } = settings
    if (// for pushes only
      !communityId &&
      typeof community === 'object' &&
      community._id &&
      community._id.length
    ) {
      q.contains('audience.audiences', [community._id]) // push notifications
        .or().equalTo('audience.defaultQuery.community_id', community._id)
      q.or().contains('obj.audience.audiences', [community._id]) // push templates
        .or().equalTo('obj.audience.defaultQuery.community_id', community._id)
      q.or().equalTo('community._id', community._id) // for pushes after upgrade
    } else {
      if (searchTerm) q.matches('name', searchTerm, { ignoreCase: true })
      if (category) {
        if (category._id) q.contains('categories', [category._id])
        else if (category.type === 'group') q.contains('categories', category.groups[category.name])
      }
      if (categoryId) q.contains('categories', [categoryId])
      if (collectionId) q.containsAll('collections', [collectionId])
      if (communityId) q.equalTo('community._id', communityId)
      if (survey) q.exists('survey._id', true)
      else if (survey === false) q.exists('survey._id', false)
      if (group && group._id) q.contains('groups', [group._id]) 
      if (team && team._id) q.contains('teams', [team._id])
      if (teams && teams.length) q.contains('teams', teams)
      if (pollQuestionId) q.equalTo('question._id', pollQuestionId)
      if (user.communityAdmin && !pollQuestionId) { // dumb logic here, poll question answers don't have a community
        q.equalTo('community._id', user.community._id)
      }
      if (notYetExported) q.notEqualTo('exported', true)
      if (segments && segments.length) q.contains('segments', segments)
      if (segmentTypes && segmentTypes.length) q.contains('segmentType._id', segmentTypes)
      if (email) q.and().equalTo('email', email)
      if (version) q.equalTo('version', version)
      if (userId) q.equalTo('user._id', userId)
      if (surveyId) q.equalTo('survey._id', surveyId)
      if (enabled !== null) q.equalTo('enabled', enabled)
      if(goals && goals.length) q.contains('goals', goals)
      if(goalsStatus && goals.length) q.contains('goalsStatus', goalsStatus)
      
    }
    q.and().notEqualTo('deleted', true)
    return q
  }

  public callEndpoint (endpoint: string, data = {}) {
    return this.bend.execute(endpoint, data)
  }

  // pagination
  // public paginationQuery (settings: {
  //   searchTerm: string
  //   category: {
  //     id: string
  //     type: string
  //     name: string
  //     groups: {}
  //   }
  //   collectionId: string
  //   communityId: string
  //   pagination: {
  //     itemsPerPage: number
  //     currentPage: number
  //   }
  //   sortDirection: {
  //     key: string
  //   }
  // }) {
  public paginationQuery (settings = this.defaultSettings) {
    let user = getActiveUser()
    let q = new this.bend.Query()

    let { searchTerm, category, collectionId, communityId, itemsPerPage, currentPage, sort, team, community } = settings
    if (searchTerm) {
      q.matches('name', settings.searchTerm, {
        ignoreCase: true
      })
    }

    if (category && !category.id) {
      if (settings.category.type === 'group') q.contains('categories', settings.category.groups[settings.category.name])
      // } else { // bad logic
      //   q.contains('categories', [settings.category.id])
    }

    if (collectionId) q.containsAll('collections', [settings.collectionId])

    if (communityId) q.equalTo('community._id', settings.communityId)

    if (// for pushes only
      !communityId &&
      typeof community === 'object' &&
      community._id &&
      community._id.length
    ) {
      q.contains('audience.audiences', [community._id]) // push notifications
        .or().equalTo('audience.defaultQuery.community_id', community._id)
      q.or().contains('obj.audience.audiences', [community._id]) // push templates
        .or().equalTo('obj.audience.defaultQuery.community_id', community._id)
      q.or().equalTo('community._id', community._id) // for pushes after upgrade
    } else {
      if (user.communityAdmin) {
        q.equalTo('community._id', user.community._id)
      }
    }

    q.notEqualTo('deleted', true)
    // q.descending("_bmd.createdAt");

    // pagination stuff
    q.limit(itemsPerPage)
    q.skip((currentPage - 1) * itemsPerPage)

    // if (sort.direction[sort.key] === 1) {
    //   q.ascending(sort.key)
    // }
    // if (sort.direction[sort.key] === -1) {
    //   q.descending(sort.key)
    // }

    if (team && team._id) q.contains('teams', [team._id])

    return q
  }

  // public fetchPaginatedList (table, settings) {
  //   return this.bend.DataStore.find(table, this.paginationQuery(settings))
  // }

  // init
  public async init () {
    const init = new Promise((resolve, reject) => {
      const promise = this.bend.init({
        appKey: this.CREDENTIALS.APP_KEY,
        appSecret: this.CREDENTIALS.APP_SECRET
      })
      resolve(promise)
    })
    try {
      const response = await init
      this.bend.setActiveUser(response)
      const user = getActiveUser()
      this.user = user
    } catch (err) {
      this.user = {
        community: {
          id: '',
          admin: false
        }
      }
      this.bend.User.logOut()
    }
  }

}

export default new BendTable()
