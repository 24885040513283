import * as _ from 'lodash'
import * as Bend from '../lib/bend-1.1.8'

export const isProduction = (): boolean =>
  process.env.NODE_ENV === 'production'

// interface UserInterface {
//   auth: {
//     token: string
//   },
//   community: {
//     id: string,
//     admin: boolean
//   }
// }

// export const getActiveUser = (): UserInterface => {
export const getActiveUser = () => {
  const user = Bend.getActiveUser()
  // const output: UserInterface = {
  const output = {
    auth: {
      token: _.get(user, '_bmd.authtoken')
    },
    community: {
      id: _.get(user, 'community._id'),
      _id: _.get(user, 'community._id'),
      admin: _.get(user, 'communityAdmin') || false
    },
    communityAdmin: _.get(user, 'communityAdmin', false),
    _id: _.get(user, '_id', null)
  }
  const outputWithLegacySupport = {
    ...user,
    ...output,
    community: {
      // ...user.community,
      ...output.community
    }
  }
  return outputWithLegacySupport
}
