
import * as moment from 'moment-timezone'
import * as _ from 'lodash'

interface CommonUtilInterface {
  PIXEL_URL: any
  formatLL: string
  formatLL5: string
  formatLL6: string
  genders: any
  AllCountries: any
  AllStates: any
  AllCategoryGroups: any
  animals: any
  months: any
  monthDays: any
  days: any
  weekdays: any
  ckeditorOptions: any
  chartTypes: Array<string>
  chartDataGuides: Array<any>
  timeScales: Array<string>
  defaultDeeplinks: Array<any>
  itemsPerPageList: Array<any>
}

class CommonUtil implements CommonUtilInterface {

  public PIXEL_URL
  public formatLL
  public formatLL5
  public formatLL6
  public genders
  public AllCountries
  public AllStates
  public AllCategoryGroups
  public animals
  public months
  public monthDays
  public days
  public weekdays
  public ckeditorOptions
  public chartTypes
  public chartDataGuides
  public timeScales
  public defaultDeeplinks
  public itemsPerPageList

  constructor () {
    this.PIXEL_URL = 'http://tracker.mymilkcrate.com/pixel'

    this.formatLL = 'MMMM D, YYYY'
    this.formatLL5 = "MMM D, 'YY"
    this.formatLL6 = "MMM D, 'YY, h:mm"

    this.genders = ['Male', 'Female', 'Transgender']

    this.AllCountries = [
      { code: 'AF', name: 'Afghanistan' },
      { code: 'AL', name: 'Albania' },
      { code: 'DZ', name: 'Algeria' },
      { code: 'AS', name: 'American Samoa' },
      { code: 'AD', name: 'Andorre' },
      { code: 'AO', name: 'Angola' },
      { code: 'AI', name: 'Anguilla' },
      { code: 'AQ', name: 'Antarctica' },
      { code: 'AG', name: 'Antigua and Barbuda' },
      { code: 'AR', name: 'Argentina' },
      { code: 'AM', name: 'Armenia' },
      { code: 'AW', name: 'Aruba' },
      { code: 'AU', name: 'Australia' },
      { code: 'AT', name: 'Austria' },
      { code: 'AZ', name: 'Azerbaijan' },
      { code: 'BS', name: 'Bahamas' },
      { code: 'BH', name: 'Bahrain' },
      { code: 'BD', name: 'Bangladesh' },
      { code: 'BB', name: 'Barbade' },
      { code: 'BY', name: 'Belarus' },
      { code: 'BE', name: 'Belgium' },
      { code: 'BZ', name: 'Belize' },
      { code: 'BJ', name: 'Benin' },
      { code: 'BM', name: 'Bermuda' },
      { code: 'BT', name: 'Bhutan' },
      { code: 'BO', name: 'Bolivia' },
      { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
      { code: 'BA', name: 'Bosnia and Herzegovina' },
      { code: 'BW', name: 'Botswana' },
      { code: 'BV', name: 'Bouvet Island' },
      { code: 'BR', name: 'Brazil' },
      { code: 'IO', name: 'British Indian Ocean Territory' },
      { code: 'VG', name: 'British Virgin Islands' },
      { code: 'BN', name: 'Brunei' },
      { code: 'BG', name: 'Bulgaria' },
      { code: 'BF', name: 'Burkina Faso' },
      { code: 'BI', name: 'Burundi' },
      { code: 'KH', name: 'Cambodia' },
      { code: 'CM', name: 'Cameroon' },
      { code: 'CA', name: 'Canada' },
      { code: 'CV', name: 'Cape Verde' },
      { code: 'KY', name: 'Cayman Islands' },
      { code: 'CF', name: 'Central African Republic' },
      { code: 'TD', name: 'Chad' },
      { code: 'CL', name: 'Chile' },
      { code: 'CN', name: 'China' },
      { code: 'CX', name: 'Christmas Island' },
      { code: 'CC', name: 'Cocos (Keeling) Islands' },
      { code: 'CO', name: 'Colombia' },
      { code: 'KM', name: 'Comoros' },
      { code: 'CG', name: 'Congo' },
      { code: 'CD', name: 'Congo (Dem. Rep.)' },
      { code: 'CK', name: 'Cook Islands' },
      { code: 'CR', name: 'Costa Rica' },
      { code: 'ME', name: 'Crna Gora' },
      { code: 'HR', name: 'Croatia' },
      { code: 'CU', name: 'Cuba' },
      { code: 'CW', name: 'Curaçao' },
      { code: 'CY', name: 'Cyprus' },
      { code: 'CZ', name: 'Czech Republic' },
      { code: 'CI', name: "Côte D'Ivoire" },
      { code: 'DK', name: 'Denmark' },
      { code: 'DJ', name: 'Djibouti' },
      { code: 'DM', name: 'Dominica' },
      { code: 'DO', name: 'Dominican Republic' },
      { code: 'TL', name: 'East Timor' },
      { code: 'EC', name: 'Ecuador' },
      { code: 'EG', name: 'Egypt' },
      { code: 'SV', name: 'El Salvador' },
      { code: 'GQ', name: 'Equatorial Guinea' },
      { code: 'ER', name: 'Eritrea' },
      { code: 'EE', name: 'Estonia' },
      { code: 'ET', name: 'Ethiopia' },
      { code: 'FK', name: 'Falkland Islands' },
      { code: 'FO', name: 'Faroe Islands' },
      { code: 'FJ', name: 'Fiji' },
      { code: 'FI', name: 'Finland' },
      { code: 'FR', name: 'France' },
      { code: 'GF', name: 'French Guiana' },
      { code: 'PF', name: 'French Polynesia' },
      { code: 'TF', name: 'French Southern Territories' },
      { code: 'GA', name: 'Gabon' },
      { code: 'GM', name: 'Gambia' },
      { code: 'GE', name: 'Georgia' },
      { code: 'DE', name: 'Germany' },
      { code: 'GH', name: 'Ghana' },
      { code: 'GI', name: 'Gibraltar' },
      { code: 'GR', name: 'Greece' },
      { code: 'GL', name: 'Greenland' },
      { code: 'GD', name: 'Grenada' },
      { code: 'GP', name: 'Guadeloupe' },
      { code: 'GU', name: 'Guam' },
      { code: 'GT', name: 'Guatemala' },
      { code: 'GG', name: 'Guernsey and Alderney' },
      { code: 'GN', name: 'Guinea' },
      { code: 'GW', name: 'Guinea-Bissau' },
      { code: 'GY', name: 'Guyana' },
      { code: 'HT', name: 'Haiti' },
      { code: 'HM', name: 'Heard and McDonald Islands' },
      { code: 'HN', name: 'Honduras' },
      { code: 'HK', name: 'Hong Kong' },
      { code: 'HU', name: 'Hungary' },
      { code: 'IS', name: 'Iceland' },
      { code: 'IN', name: 'India' },
      { code: 'ID', name: 'Indonesia' },
      { code: 'IR', name: 'Iran' },
      { code: 'IQ', name: 'Iraq' },
      { code: 'IE', name: 'Ireland' },
      { code: 'IM', name: 'Isle of Man' },
      { code: 'IL', name: 'Israel' },
      { code: 'IT', name: 'Italy' },
      { code: 'JM', name: 'Jamaica' },
      { code: 'JP', name: 'Japan' },
      { code: 'JE', name: 'Jersey' },
      { code: 'JO', name: 'Jordan' },
      { code: 'KZ', name: 'Kazakhstan' },
      { code: 'KE', name: 'Kenya' },
      { code: 'KI', name: 'Kiribati' },
      { code: 'KP', name: 'Korea (North)' },
      { code: 'KR', name: 'Korea (South)' },
      { code: 'KW', name: 'Kuwait' },
      { code: 'KG', name: 'Kyrgyzstan' },
      { code: 'LA', name: 'Laos' },
      { code: 'LV', name: 'Latvia' },
      { code: 'LB', name: 'Lebanon' },
      { code: 'LS', name: 'Lesotho' },
      { code: 'LR', name: 'Liberia' },
      { code: 'LY', name: 'Libya' },
      { code: 'LI', name: 'Liechtenstein' },
      { code: 'LT', name: 'Lithuania' },
      { code: 'LU', name: 'Luxembourg' },
      { code: 'MO', name: 'Macao' },
      { code: 'MK', name: 'Macedonia' },
      { code: 'MG', name: 'Madagascar' },
      { code: 'MW', name: 'Malawi' },
      { code: 'MY', name: 'Malaysia' },
      { code: 'MV', name: 'Maldives' },
      { code: 'ML', name: 'Mali' },
      { code: 'MT', name: 'Malta' },
      { code: 'MH', name: 'Marshall Islands' },
      { code: 'MQ', name: 'Martinique' },
      { code: 'MR', name: 'Mauritania' },
      { code: 'MU', name: 'Mauritius' },
      { code: 'YT', name: 'Mayotte' },
      { code: 'MX', name: 'Mexico' },
      { code: 'FM', name: 'Micronesia' },
      { code: 'MD', name: 'Moldova' },
      { code: 'MC', name: 'Monaco' },
      { code: 'MN', name: 'Mongolia' },
      { code: 'MS', name: 'Montserrat' },
      { code: 'MA', name: 'Morocco' },
      { code: 'MZ', name: 'Mozambique' },
      { code: 'MM', name: 'Myanmar' },
      { code: 'NA', name: 'Namibia' },
      { code: 'NR', name: 'Nauru' },
      { code: 'NP', name: 'Nepal' },
      { code: 'NL', name: 'Netherlands' },
      { code: 'AN', name: 'Netherlands Antilles' },
      { code: 'NC', name: 'New Caledonia' },
      { code: 'NZ', name: 'New Zealand' },
      { code: 'NI', name: 'Nicaragua' },
      { code: 'NE', name: 'Niger' },
      { code: 'NG', name: 'Nigeria' },
      { code: 'NU', name: 'Niue' },
      { code: 'NF', name: 'Norfolk Island' },
      { code: 'MP', name: 'Northern Mariana Islands' },
      { code: 'NO', name: 'Norway' },
      { code: 'OM', name: 'Oman' },
      { code: 'PK', name: 'Pakistan' },
      { code: 'PW', name: 'Palau' },
      { code: 'PS', name: 'Palestine' },
      { code: 'PA', name: 'Panama' },
      { code: 'PG', name: 'Papua New Guinea' },
      { code: 'PY', name: 'Paraguay' },
      { code: 'PE', name: 'Peru' },
      { code: 'PH', name: 'Philippines' },
      { code: 'PN', name: 'Pitcairn' },
      { code: 'PL', name: 'Poland' },
      { code: 'PT', name: 'Portugal' },
      { code: 'PR', name: 'Puerto Rico' },
      { code: 'QA', name: 'Qatar' },
      { code: 'RO', name: 'Romania' },
      { code: 'RU', name: 'Russia' },
      { code: 'RW', name: 'Rwanda' },
      { code: 'RE', name: 'Réunion' },
      { code: 'BL', name: 'Saint Barthélemy' },
      { code: 'SH', name: 'Saint Helena' },
      { code: 'KN', name: 'Saint Kitts and Nevis' },
      { code: 'LC', name: 'Saint Lucia' },
      { code: 'MF', name: 'Saint Martin' },
      { code: 'PM', name: 'Saint Pierre and Miquelon' },
      { code: 'VC', name: 'Saint Vincent and the Grenadines' },
      { code: 'WS', name: 'Samoa' },
      { code: 'SM', name: 'San Marino' },
      { code: 'SA', name: 'Saudi Arabia' },
      { code: 'SN', name: 'Senegal' },
      { code: 'RS', name: 'Serbia' },
      { code: 'SC', name: 'Seychelles' },
      { code: 'SL', name: 'Sierra Leone' },
      { code: 'SG', name: 'Singapore' },
      { code: 'SX', name: 'Sint Maarten' },
      { code: 'SK', name: 'Slovakia' },
      { code: 'SI', name: 'Slovenia' },
      { code: 'SB', name: 'Solomon Islands' },
      { code: 'SO', name: 'Somalia' },
      { code: 'ZA', name: 'South Africa' },
      { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
      { code: 'SS', name: 'South Sudan' },
      { code: 'ES', name: 'Spain' },
      { code: 'LK', name: 'Sri Lanka' },
      { code: 'SD', name: 'Sudan' },
      { code: 'SR', name: 'Suriname' },
      { code: 'SJ', name: 'Svalbard and Jan Mayen' },
      { code: 'SZ', name: 'Swaziland' },
      { code: 'SE', name: 'Sweden' },
      { code: 'CH', name: 'Switzerland' },
      { code: 'SY', name: 'Syria' },
      { code: 'ST', name: 'São Tomé and Príncipe' },
      { code: 'TW', name: 'Taiwan' },
      { code: 'TJ', name: 'Tajikistan' },
      { code: 'TZ', name: 'Tanzania' },
      { code: 'TH', name: 'Thailand' },
      { code: 'TG', name: 'Togo' },
      { code: 'TK', name: 'Tokelau' },
      { code: 'TO', name: 'Tonga' },
      { code: 'TT', name: 'Trinidad and Tobago' },
      { code: 'TN', name: 'Tunisia' },
      { code: 'TR', name: 'Turkey' },
      { code: 'TM', name: 'Turkmenistan' },
      { code: 'TC', name: 'Turks and Caicos Islands' },
      { code: 'TV', name: 'Tuvalu' },
      { code: 'UG', name: 'Uganda' },
      { code: 'UA', name: 'Ukraine' },
      { code: 'AE', name: 'United Arab Emirates' },
      { code: 'GB', name: 'United Kingdom' },
      { code: 'UM', name: 'United States Minor Outlying Islands' },
      { code: 'US', name: 'United States of America' },
      { code: 'UY', name: 'Uruguay' },
      { code: 'UZ', name: 'Uzbekistan' },
      { code: 'VU', name: 'Vanuatu' },
      { code: 'VA', name: 'Vatican City' },
      { code: 'VE', name: 'Venezuela' },
      { code: 'VN', name: 'Vietnam' },
      { code: 'VI', name: 'Virgin Islands of the United States' },
      { code: 'WF', name: 'Wallis and Futuna' },
      { code: 'EH', name: 'Western Sahara' },
      { code: 'YE', name: 'Yemen' },
      { code: 'ZM', name: 'Zambia' },
      { code: 'ZW', name: 'Zimbabwe' },
      { code: 'AX', name: 'Åland Islands' }
    ]

    this.AllStates = [
      'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL',
      'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH',
      'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
      'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC',
      'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
    ]

    this.AllCategoryGroups = [
      'Community',
      'Diet',
      'Home',
      'Shopping',
      'Transit',
      'Waste'
    ]

    this.animals = [
      'cat', 'corgi', 'fish', 'frog', 'koala', 'lion', 'otter', 'owl',
      'penguin', 'pig', 'raccoon', 'rhino', 'squirrel', 'turtle', 'whale'
    ]

    this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    this.days = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
    ]

    this.weekdays = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]

    this.months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]

    this.monthDays = {
      Jan: 31,
      Feb: 28,
      Mar: 31,
      Apr: 30,
      May: 31,
      Jun: 30,
      Jul: 31,
      Aug: 31,
      Sep: 30,
      Oct: 31,
      Nov: 30,
      Dec: 31
    }

    this.itemsPerPageList = [50, 100, 200]

    this.ckeditorOptions = {
      language: 'en',
      allowedContent: true,
      entities: false,
      height: 100,
      toolbar: [
        {
          name: 'links',
          items: ['Link', 'Unlink', 'Anchor']
        },
        {
          name: 'basicstyles',
          items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
        }
      ]
    }

    this.chartTypes = {
      nonPie: [{
        type: 'bar',
        name: 'Bar'
      }, {
        type: 'line',
        name: 'Line'
      }],
      pie: [{
        type: 'pie',
        name: 'Pie'
      }],
      all: [{
        type: 'bar',
        name: 'Bar'
      }, {
        type: 'line',
        name: 'Line'
      }, {
        type: 'pie',
        name: 'Pie'
      }]
    }

    // data is top level, first thing a user chooses
    // options are dependent on data
    // subOptions are dependent on data or on options
    // timeOptions are dependent on subOptions
    // countOptions are  dependent on options or subOptions
    this.chartDataGuides = {
      data: [{
        text: 'Users',
        table: 'user',
        code: 'users',
        options: 'users'
      }, { // option.code and timeOption.code are used here
        text: 'User Activity',
        table: 'activity',
        code: 'activity',
        options: 'activity',
        subOptions: 'countPointsTimes'
      }, {
        text: 'App Views',
        endpoint: 'chart_appViews',
        code: 'app views',
        options: 'views',
        type: 'bar'
      }],
      options: {
        activity: [{
          text: 'All Activity',
          code: 'activeUsers all activity poll business event-registration event-checkin volunteer_opportunity customActivity action comment post'
        }, {
          text: 'Activity by Type',
          code: 'activitybytype',
          table: 'activity',
          type: 'pie',
          subOptions: false,
          countOption: false
        }, {
          text: 'Places',
          code: 'business'
        }, {
          text: 'Place Check-Ins by Location',
          // code: 'place check-ins by location',
          table: 'activity',
          value: 'business',
          code: 'business',
          subOptions: false,
          type: 'bar'
        }, {
          text: 'Event Registration',
          code: 'event-registration'
        }, {
          text: 'Event Check-In',
          code: 'event-checkin'
        }, {
          text: 'Actions',
          code: 'action'
        }, {
          text: 'Volunteer Opportunities',
          code: 'volunteer_opportunity'
        }, {
          text: 'Custom Activity',
          code: 'customActivity'
        }, {
          text: 'Poll Questions Answered',
          code: 'poll'
        }],
        users: [{
          text: 'New Users',
          code: 'new users',
          countOptions: true
        }, {
        //   text: 'Active Users',
        //   table: 'activity',
        //   code: 'active users poll business event-registration event-checkin volunteer_opportunity customActivity action comment post',
        //   // countOptions: true
        // }, {
          text: 'Demographics',
          code: 'demographics',
          endpoint: 'chart_communityDemographics',
          subOptions: 'demographics',
          type: 'pie'
        }],
        views: [{
          text: 'Places',
          code: 'business'
        }, {
          text: 'Events',
          code: 'event'
        }, {
          text: 'Actions',
          code: 'action'
        }, {
          text: 'Volunteer Opportunities',
          code: 'volunteer_opportunity'
        }]
        // place business, events,actions, vol, custom -- views
      },
      subOptions: {
        demographics: [{
          text: 'Gender',
          code: 'gender'
        }, {
          text: 'Age',
          code: 'age'
        }, {
          text: 'Race',
          code: 'race'
        }, {
          text: 'Ethnicity',
          code: 'ethnicity'
        }],
        countPointsTimes: [{
          text: 'Count',
          code: 'count',
          countOptions: true
        }, {
          text: 'Points',
          code: 'points',
          countOptions: true,
          yAxisLabel: 'Points'
        }, {
          text: 'Times',
          code: 'times',
          timeOptions: true,
          type: 'pie'
        }]
      },
      timeOptions: [{
        text: 'Time of day',
        code: 'timeOfDay',
        endpoint: 'chart_activityTimes'
      }, {
        text: 'Day of week',
        code: 'dayOfWeek',
        endpoint: 'chart_activityTimes'
      }],
      countOptions: [{
        text: 'Running Total',
        code: 'runningTotal'
      }, {
        text: 'Week by Week',
        code: 'weekByWeek'
      }]
    }

    this.timeScales = [
      'Days',
      'Weeks',
      'Months'
    ]

    this.defaultDeeplinks = [
      {
        name: 'Home',
        deeplink: '://home'
      },
      {
        name: 'Alerts',
        deeplink: '://alert'
      },
      {
        name: 'Profile',
        deeplink: '://profile'
      },
      {
        name: 'Community',
        deeplink: '://community'
      },
      {
        name: 'Settings',
        deeplink: '://settings'
      },
      {
        name: 'Feedback',
        deeplink: '://settings/feedback'
      },
      {
        name: 'Suggest a business or event',
        deeplink: '://settings/suggest'
      },
      {
        name: 'Search',
        deeplink: '://search'
      },
      {
        name: 'Search: Recent Activities',
        deeplink: '://search/recent'
      },
      {
        name: 'Search: Businesses',
        deeplink: '://search/businesses'
      },
      {
        name: 'Search: Actions',
        deeplink: '://search/action'
      },
      {
        name: 'Search: Volunteer Opportunities',
        deeplink: '://search/volunteer_opportunities'
      },
      {
        name: 'Search: Events',
        deeplink: '://search/events'
      }
    ]
  }

  sortBy (list, key, direction) {
    let sortedList = list.sort((a, b) => {
      if (key === 'name' || key === 'text' || key === 'comment') { // text values
        if (direction[key] === 1) {
          return ('' + a[key]).localeCompare(b[key])
        } else {
          return ('' + b[key]).localeCompare(a[key])
        }
      }
      let aKey = _.get(a, key, null)
      let bKey = _.get(b, key, null)
      // string dates
      if (
        (typeof aKey === 'string' || typeof bKey === 'string') &&
        (new Date(aKey) || new Date(bKey))
      ) {
        let aDate = new Date(aKey).getTime()
        let bDate = new Date(bKey).getTime()
        if (direction[key] === -1) return aDate - bDate
        if (direction[key] === 1) return bDate - aDate
      } else { // others
        if (direction[key] === -1) return aKey - bKey
        if (direction[key] === 1) return bKey - aKey
      }
    })
    return sortedList
  }

  formatDate (ts) {
    if (ts === '' || ts == null) { return '' }

    return moment.unix(ts / 1000000000).format(this.formatLL)
  }

  formatSimpleDate (ts) {
    return moment.unix(ts / 1000000000).format(this.formatLL5)
  }

  formatDateWithFormat (ts, formatStr) {
    if (ts === '' || ts == null) { return '' }
    return moment.unix(ts / 1000000000).format(formatStr)
  }

  formatDateWithFormatUTC (ts, formatStr) {
    if (ts === '' || ts == null) { return '' }
    return moment(ts).format(formatStr)
  }

  formatDateWithFormatWithTimezone (ts, formatStr, tz) {
    if (ts === '' || ts == null) { return '' }
    if (typeof ts === 'number') ts = new Date(ts).getTime() * 1000000000

    return moment.tz(new Date(ts / 1000000), tz).format(formatStr)
  }

  formatDateTime2 (datetimeStr) {
    if (datetimeStr === '' || datetimeStr == null || typeof datetimeStr === 'undefined') { return moment.unix(Date.now() / 1000).format('YYYY-MM-DD HH:mm') }

    if (typeof datetimeStr === 'string') { return datetimeStr } else { return moment.unix(datetimeStr.getTime() / 1000).format('YYYY-MM-DD HH:mm') }
  }

  formatDateTime (ts) {
    if (ts === '' || ts == null) { return '' }

    return moment.unix(ts / 1000000000).format(this.formatLL + ' h:mm a')
  }

  formatDateTimeWithFormat (datetimeStr, ts) {
    ts = new Date(datetimeStr).getTime()
    return moment.unix(ts / 1000).format(this.formatLL + ' h:mm a')
  }

  formatSimpleDateTime (ts) {
    return moment.unix(ts / 1000000000).format(this.formatLL5 + ' h:mm a')
  }

  isPastDay (ts) {
    if (typeof ts === 'undefined') { return false } else {
      let delta = (ts / 1000000000 - (new Date().getTime()) / 1000)
      if (delta < 0) {
        return true
      } else { return false }
    }
  }

  convertPushStringToDate (str) {
    let date = moment(str).toString().slice(4,21)
    if (date === 'Invalid date') date = ''
    return date
  }

  convertStringToDate (str, dateFormat) {
    return moment(str, dateFormat).toDate()
  }

  convertBendTimeToDate (num) {
    return moment(num / 1000000).toString()
  }

  timesToTimeStr (item) {
    if (typeof item.startsAt === 'number') item.startsAt = this.bendTimeToString(item, true) // for events
    if (typeof item.endsAt === 'number') item.endsAt = this.bendTimeToString(item, false)
    let startArr = []
    let endArr = []
    let timeStr = ''
    if (item.startsAt) startArr = item.startsAt.split(' ') // events
    if (item.endsAt) endArr = item.endsAt.split(' ')
    if (item.startDate) startArr = item.startDate.split('-') // charts
    if (item.endDate) endArr = item.endDate.split('-')
    if (item.startsAt && item.endsAt) timeStr = startArr.slice(0, 5).join(' ') + ' to ' + endArr.slice(3, 5).join(' ')
    else if (item.startDate && item.endDate) timeStr = startArr.slice(1).join('/') + ' to ' + endArr.slice(1).join('/')
    else timeStr = 'Date missing'
    return timeStr
  }

  bendTimeToString (item, start?) { // for events i think
    const prop = start ? 'startsAt' : 'endsAt'
    if (item[prop] === null) {
      if (item.times.length !== 1) {
        return 'invalid'
      }
      const { date } = item.times[0]
      const time = start ? item.times[0].from : item.times[0].until
      return moment(`${date} ${this.convertTo24Hour(time)}`).format(`MMM D, 'YY h:mm a`)
    } else {
      return this.formatSimpleDateTime(item[prop])
    }
  }

  convertTo24Hour (time) {
    let hours = parseInt(time.substr(0, 2), 10)
    if (time.indexOf('am') !== -1 && Number(hours) === 12) {
      time = time.replace('12', '0')
    }
    if (time.indexOf('pm') !== -1 && hours < 12) {
      time = time.replace(hours, (hours + 12))
    }
    return time.replace(/(am|pm)/, '')
  }

  objectsAreSame (x, y = {}) {
    let objectsAreSame = true
    for (let propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false
        break
      }
    }
    return objectsAreSame
  }

  isTweetURL (url) {
    if (url.indexOf('https://twitter.com/') === 0) {
      if (url.indexOf('/status/') !== -1) { return true }
    }

    return false
  }

  getNamesFromList (idArr: Array<string> = [], objList: Array<any>) {
    if (!idArr ||
      idArr.length === 0 ||
      idArr.length === (objList.length - 1)
    ) {
      return 'All Users'
    }

    let names: Array<string> = []
    _.map(objList, function (o) {
      if (idArr.indexOf(o._id) !== -1) { names.push(o.name) }
    })

    return names.join(',')
  }

  getAge (ts) {
    if (ts == null || ts === '') {
      return ''
    }

    let days = Math.floor((Date.now() / 1000 - ts / 1000000000) / 3600 / 24)

    if (days < 1) {
      return '-1 day-old'
    } else if (days < 365) {
      return days + ' day-old'
    } else {
      let years = Math.floor(days / 365)
      return years + ' yo'
    }
  }

  getPastDateTime (ts) {
    if (ts == null || ts === '') {
      return ''
    }

    let mins = Math.floor((Date.now() / 1000 - ts / 1000000000) / 60)

    if (mins < 0) {
      return 'just now'
    } else if (mins < 60) {
      return mins + ' minutes ago'
    } else if (mins < 24 * 60) {
      return this.formatDateWithFormat(ts, 'h:mm a')
    } else {
      return this.formatSimpleDateTime(ts)
    }
  }

  metersToFeet (meters) {
    // the precise number is 3.28084 but that will cause a large decimal on the front end
    // we could round it but then it will change every time it is saved
    return meters * 3
  }

  feetToMeters (feet) {
    // the precise number is 3.28084 but that will cause a large decimal on the front end
    // we could round it but then it will change every time it is saved
    return feet / 3
  }

  getGenderCode (text) {
    if (text === 'Male') {
      return 0
    } else if (text === 'Female') {
      return 1
    }

    return 2
  }

  convertArrayToString (list) {
    let ret = ''

    if (list) {
      ret = list.join(', ')
    }

    return ret
  }

  convertArrayToString2 (list) {
    let ret = ''

    if (list) {
      for (let i = 0; i <= list.length - 1; i++) {
        if (i < list.length - 2) {
          ret += list[i] + ', '
        } else if (i < list.length - 1) {
          ret += list[i] + ' or '
        } else {
          ret += list[i]
        }
      }
    }

    return ret
  }

  checkFileType (type, tag) {
    let ret = false
    if (tag === 'image') {
      ret = type.match(/image.*/)
    } else if (tag === 'video') {
      ret = type.match(/video.*/)
    }

    return ret
  }

  isGif (file) {
    if (file.type.toLowerCase().indexOf('gif') !== -1) {
      return true
    }

    return false
  }

  getFileAttr (file, attr) {
    if (!file) {
      return ''
    }

    if (file._file) {
      file = file._file
    }

    return file[attr]
  }

  getSmallImage (file) {
    if (!file) {
      return '/assets/img/profile.jpg'
    }

    if (file._file) {
      file = file._file
    }

    if (file._versions) {
      if (file._versions.sm) {
        return file._versions.sm._downloadURL
      } else {
        return file._downloadURL
      }
    } else {
      return file._downloadURL
    }
  }

  getSmallImage2 (file) {
    if (!file) {
      return '/assets/img/no-image.png'
    }

    if (file._file) {
      file = file._file
    }

    if (file._versions) {
      if (file._versions.sm) {
        return file._versions.sm._downloadURL
      } else {
        return file._downloadURL
      }
    } else {
      return file._downloadURL
    }
  }

  getSmallImage3 (file, showOriginal) {
    if (!file) {
      return ''
    }

    if (file.type === 'search') {
      if (showOriginal) {
        return file.original
      } else {
        return file.preview
      }
    }

    if (file._file) {
      file = file._file
    }

    if (file._versions) {
      if (file._versions.sm) {
        return file._versions.sm._downloadURL
      } else {
        return file._downloadURL
      }
    } else {
      return file._downloadURL
    }
  }
  getAvatarThumbnail (avatar, user) {
    if (!avatar) {
      if (user && user.defaultAvatar) {
        return '/assets/img/avatars/' + user.defaultAvatar + '.png'
      } else {
        return '/assets/img/profile.jpg'
      }
    }

    if (avatar._file) {
      return this.getSmallImage(avatar._file)
    } else {
      return this.getSmallImage(avatar)
    }
  }

  goUserDetail (user, e) {
    // needs refactoring
    // if (user && user._id) {
    //   if (e) {
    //     e.preventDefault()
    //     e.stopPropagation()
    //   }

    //   if (!$bend.getActiveUser().communityAdmin) {
    //     $location.url('/users/' + user._id)
    //   } else {
    //     $location.url('/communityUsers/' + user._id)
    //   }
    // }
  }

  getDownloadURL (imageObj) {
    if (typeof imageObj === 'string') return imageObj
    if (!imageObj) {
      return null
    }

    if (imageObj._file) {
      return imageObj._file._downloadURL
    } else {
      return imageObj._downloadURL
    }
  }

  openURL (e, image) {
    let url = this.getDownloadURL(image)
    if (url) {
      e.preventDefault()
      e.stopPropagation()

      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  }

  convertReturn (text) {
    if (!text) { return '' }

    text = text.replace(/\n/g, '<br>')

    return text
  }

  encodedText (text) {
    if (!text) return ''

    return encodeURIComponent(text)
  }

  convertWithAMPM (hour) {
    hour = hour.toLowerCase()
    if (hour.indexOf('am') !== -1 || hour.indexOf('pm') !== -1) {
      return undefined
    }

    let d = hour
    let endPart = ':00'

    if (d.indexOf(':') !== -1) {
      endPart = d.substr(d.indexOf(':'))
      d = Number(d.substr(0, d.indexOf(':')))
    }

    if (d === 0) {
      return '12' + endPart + ' am'
    } else if (d <= 11) {
      return d + endPart + ' am'
    } else if (d === 12) {
      return '12' + endPart + ' pm'
    } else if (d > 12) {
      return (d - 12) + endPart + ' pm'
    }
  }

  getMiddleImage (file) {
    if (file._versions) {
      if (file._versions.md) {
        return file._versions.md._downloadURL
      } else {
        return file._downloadURL
      }
    } else {
      return file._downloadURL
    }
  }

  convertBoolean (val) {
    return val ? 'Yes' : 'No'
  }

  ifnull (val, def) {
    return val || def
  }

  makeBendRef (id, collectionName) {
    return {
      '_type': 'BendRef',
      '_id': id,
      '_collection': collectionName
    }
  }

  makeBendFile (id) {
    return {
      '_type': 'BendFile',
      '_id': id
    }
  }

  getFullName (user) {
    if (!user) { return '' }

    return this.ifnull(user.lastName, '') + ' ' + this.ifnull(user.firstName, '')
  }

  getFullName2 (user) {
    if (!user) { return '' }

    let temp: any = []

    if (user.lastName) {
      temp.push(user.lastName)
    }

    if (user.firstName) {
      temp.push(user.firstName)
    }

    return temp.join(', ')
  }

  replaceReturn (text) {
    if (typeof text !== 'undefined') { return text.replace(/\n/g, '<br>') } else { return '' }
  }

  isRetina () {
    /* if (window.matchMedia) {
      var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
      return (mq && mq.matches || (window.devicePixelRatio > 1));
    } */
    return false
  }

  jsonValidate (value) {
    if (value) {
      try {
        JSON.parse(value)
      } catch (e) {
        if (e) {
          return ('Object has errors')
        }
      }
    }
  }

  objArrsToIds (keys, formData, obj) {
    keys.map(arr => {
      if (formData[arr].length > 0) {
        obj[arr] = formData[arr].map(obj => obj._id)
      } else {
        delete obj[arr]
      }
    })
    return obj
  }

  arrObjsToIds (arr, obj) {
    if (arr.length > 0) {
      obj[arr] = arr.map(obj => obj._id)
    } else {
      delete obj[arr]
    }
    return obj
  }

  idsToObjArr (ids, arr) {
    let returningArr = []
    if (ids.length > 0) {
      returningArr = arr.filter(obj => {
        return ids.indexOf(obj._id) !== -1
      })
    }
    return returningArr
  }

  getIdList (list) {
    let ret: any = []
    _.map(list, function (o) {
      ret.push(o._id)
    })

    return ret
  }

  getIdListAndName (item) {
    let ret: any = []
    const listAndName = {
      "_id": item._id,
      "name": item.name
     }
    ret.push(listAndName);

    
    // _.map(list, function(o) {
    //   console.log("o is: ", o); 
    //   const listAndName = {
    //    "_id": o._id, 
    //    "name": o.name
    //   }
    //   ret.push(listAndName); 
    // })
    return ret
  }

  randomString (len, bits) {
    bits = bits || 36
    let outStr = ''
    let newStr = ''
    while (outStr.length < len) {
      newStr = Math.random().toString(bits).slice(2)
      outStr += newStr.slice(0, Math.min(newStr.length, (len - outStr.length)))
    }
    return outStr.toUpperCase()
  }

  getToday () {
    let dateStr = moment.unix(Date.now() / 1000).format('YYYY/MM/DD')
    return moment(dateStr, 'YYYY/MM/DD').toDate().getTime()
  }

  getDateOfWeek () {
    // Calcing the starting point
    let today = new Date(new Date().setHours(0, 0, 0, 0))
    let day = today.getDay()
    let date = today.getDate() - day

    // Grabbing Start/End Dates
    let StartDate = new Date(today.setDate(date))
    let EndDate = new Date(today.setDate(date + 6))
    return [StartDate, EndDate]
  }

  getUser (user) {
    if (!user) return '-'

    return user.name
  }

  setStorage (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  getStorage (key) {
    let value = localStorage.getItem(key)
    return value && JSON.parse(value)
  }

  getPixelUrl (type, id) {
    return this.PIXEL_URL + '/' + type + '/' + (id || '')
  }

}

export default new CommonUtil()
// export default new commonUtil()
