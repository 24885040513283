/**
 * An AngularJS directive for Dropzone.js, http://www.dropzonejs.com/
 * 
 * Usage:
 * 
 * <div ng-app="app" ng-controller="SomeCtrl">
 *   <button dropzone="dropzoneConfig">
 *     Drag and drop files here or click to upload
 *   </button>
 * </div>
 */

import bendHelper from '../../src/helpers/bendHelper'
import commonUtil from "../helpers/commonUtil";

angular.module('dropzone', []).directive('dropzone', ["$timeout", function ($timeout) {

  return {
      restrict:'AE',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
          var init = function () {
              var config, dropzone;
              scope.isLoading = true                
              config = scope[attrs.dropzone];
              dropzone = new Dropzone(element[0], config.options);
              // Display existing files on server                
              if(ngModel.$viewValue !=='' && ngModel.$viewValue !==undefined && ngModel.$viewValue.length > 0){
                  bendHelper.getFiles(ngModel.$viewValue)
                  .then(images => {
                      images.forEach(icon => {
                          // If the thumbnail is already in the right size on your server:
                          if (icon && icon._downloadURL){//We check the object is full and not just the reference
                              let mockFile = { name: icon._filename, size: icon.size, id: icon._id };
                              let callback = null; // Optional callback when it's done
                              let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
                              let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
                              dropzone.displayExistingFile(mockFile, commonUtil.getSmallImage2(icon), callback, crossOrigin, resizeThumbnail);
                          }
                      })
                      scope.isLoading = false
                  })
              } else {
                  scope.isLoading = false
              }
              
              dropzone.on("addedfile", function(file) {                    
                  if (scope.isLoading){
                      return //The dropzone might be loading the previews from server so we dont want the dropzone to upload the files again and duplicate them
                  } else {
                      bendHelper.upload(file, (error, uploadedFile) => {
                          file.previewElement.querySelector('.dz-progress').classList.toggle('dz-progress', false);
                          if (error) {
                            console.log(error);
                            file.previewElement.classList.toggle('dz-error', true)
                            file.previewElement.classList.toggle('dz-success', false)
                            return
                          }
                          bendHelper.getFile(uploadedFile, (o) => {
                           const items = ngModel.$viewValue || []; 
                           items.push(o._id)
                           ngModel.$setViewValue(items)
                           file.previewElement.classList.toggle('dz-error', false)
                           file.previewElement.classList.toggle('dz-success', true)                         
                          })
                        }, {
                          _workflow: 'coverPhoto'
                        }, (total, prog) => {
                          applyChangesOnScope($scope, () => {
                          //   $scope.fileProgress[tag] = prog * 100 / total
                          })
                        })
                  }
                  
                });
          
              dropzone.on("removedfile", function(file) {
                  const id = file.id;
                  const items = ngModel.$viewValue || []; 
                  const index = items.indexOf(id);
                  if (index > -1) {
                      items.splice(index, 1);
                  }
                  ngModel.$setViewValue(items)
                  bendHelper.deleteFile(id)
              });
          
          };
          $timeout(init, 0);
      }
  }
  
}]);
    
  angular.module('app.controllers').controller('DropzoneController', ["$scope", function ($scope) {
    $scope.dropzoneConfig = {
      'options': { // passed into the Dropzone constructor
        'url': 'fakeurl.com', //We dont use this, just placeholder so Dropzone starts
        maxFileSize: 2,
        autoProcessQueue: false, //We will handle the upload with bend and the post request
        acceptedFiles: 'image/jpg, image/png',
        addRemoveLinks: true
      }
    };
  }]);