import '@angular/platform-browser'
import '@angular/platform-browser-dynamic'
import '@angular/core'
import '@angular/common'
import '@angular/http'
import '@angular/router'

import 'rxjs'
import 'jquery'
import 'moment'
import 'underscore'
import 'angular'
import 'angular-resource'
import 'angular-cookies'
import 'angular-sanitize'
import 'angular-route'
import 'angular-animate'
import 'bootstrap'
import 'angular-bootstrap'
import 'jquery-ui'
import 'ui-select'
import 'imports-loader?this=>window!./lib/angular-ckeditor'
import 'flot'
import './lib/moment-timezone'
import 'icheck'
import 'bootbox'
import 'enquire.js'
import 'nanoscroller'
import './lib/scrollable'
import 'rangy'
import './lib/ng-sortable'
import 'switchery'
import './lib/ng-switchery'
import './lib/angular.easypiechart.js'
import 'oclazyload'
import 'angular-local-storage'
import 'angular-base64'
import './lib/skylo.js'
import './lib/velocity'
import 'angular-bootstrap-datetimepicker'
import 'ng-csv'
import 'angular-ui-ace'
import 'angular-bootstrap-colorpicker'
import 'angular-ui-calendar'
import './lib/angular-ckeditor'
import './lib/fullcalendar'
import './lib/dropzone-directive.js'
import 'async'
import 'expose-loader?PNotify!pnotify'
import 'expose-loader?alertify!alertifyjs'
