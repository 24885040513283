
import * as moment from 'moment'
import * as _ from 'lodash'
import * as parallel from 'async/parallel'
import * as Bend from '../../lib/bend-1.1.8'
import commonUtil from '../../helpers/commonUtil'
import { List } from '../communities/reducers/list'
import { Challenges } from '../challenges/reducers/list'
import { PollQuestions } from '../pollQuestions/reducers/list'
import { Sprints } from '../sprints/reducers/list'
import { Categories } from '../categories/reducers/list'
import { PushNotifications } from '../pushNotifications/reducers/list'
import { PushTemplates } from '../pushTemplates/reducers/list'
import { EventTemplates } from '../eventTemplates/reducers/list'
import { Organizations } from '../organizations/reducers/list'
import { Teams } from '../teams/reducers/list'
import { getActiveUser } from '../../helpers'
import { letProto } from 'rxjs/operator/let'
import BendTable from './BendTable'
// export { BendTable } from './BendTable'

async function asyncForEach (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

class BendClient {

  public bendTable: any
  private bend: any
  private CREDENTIALS: {
    APP_KEY: string,
    APP_SECRET: string,
    APP_ADMIN_GROUP: string,
    APP_URL: string
  }
  private user: {
    community: {
      id: string
      admin: boolean
    }
  }

  constructor () {
    this.CREDENTIALS = {
      APP_KEY: '589d36e94bad3014f50128ce',
      APP_SECRET: 'deduKe8DAuA1ry2cYYQXSQEFHgZy9qTvrL0D2lsc',
      APP_ADMIN_GROUP: 'admin',
      APP_URL: 'https://api.bend.io/group/' // XXX APP_URL name isn't accurate
    }
    this.bend = Bend
    this.bendTable = BendTable
    this.user = {
      community: {
        id: '',
        admin: false
      }
    }
  }

  public getAnswerListClient (api2, id) {
    return new Promise((resolve, reject) => {
      const params = {
        relations: {
            question: 'pollQuestion'
          }
      }
      const settings = {
        pollQuestionId: id
      }
      api2.getList(BendTable.Tables.POLL_QUESTION_ANSWER, settings, params).then(list => {
        resolve(list)
      })
    })
  }

  public async fetchCommunityList (): Promise<List> {
    const communities = await this.fetchListWithQuery<List>('community', this.querySortedByName(), {
      relations: {
        logo: 'BendFile'
      }
    })

    const communitiesWithClientApp = communities.map(async item => {
      const communityId = item._id; 
      const query = this.defaultQuery();
      query.equalTo("community._id", communityId); 
      const clientAppConfig = await this.bend.DataStore.find("clientAppConfig", query)

      return {
        ...item,
        clientAppConfig
      }
    })

    const allCommunities = Promise.all(communitiesWithClientApp); 
    return allCommunities; 
  }

  public async fetchCollectionList (): Promise<List> {
    const q = this.querySortedByName()
    if (getActiveUser().communityAdmin) {
      q.equalTo('community._id', getActiveUser().community._id)
    }

    const collections = await this.fetchListWithQuery<List>('collection', q, {
      relations: {
        coverImage: 'BendFile',
        community: 'community'
      }
    })
    return collections
  }

  public async fetchEnabledCommunityList (): Promise<List> {
    const q = this.querySortedByName()
    q.equalTo('enabled', true)

    const communities = await this.fetchListWithQuery<List>('community', q)
    return communities
  }

  public async fetchEnabledCollectionList (): Promise<List> {
    const q = this.querySortedByName()
    q.equalTo('enabled', true)
    if (getActiveUser().communityAdmin) {
      q.and(new this.bend.Query().equalTo('community._id', getActiveUser().community._id))
    }
    const collections = await this.fetchListWithQuery<List>('collection', q)
    return collections
  }

  public fetchStatisticsData (user): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fetchStatisticsDataWithLogic(user, (err, res) => {
        if (err) {
            console.log('signing out and reloading due to bad user data or db error')
            localStorage.clear() // attempt to clear incorrect user data causing code to show in statistics
            location.reload(true) // hard refresh page
            reject(err)
          } else {
            resolve(res)
          }
      })
    })
  }

  public async fetchInputList (): Promise<Sprints> {
    const q = this.defaultQuery()
    q.ascending('_bmd.createdAt')

    const sprints = await this.fetchListWithQuery<any>('sprint', q, {
      relations: {
        'collection': 'collection'
      }
    })
    const sprintsWithNames = sprints.map((i) => {
      return {
        ...i,
        name: `${i.startDate} ~ ${i.endDate}`
      }
    })
    return sprintsWithNames
  }

  public async fetchCategoriesRecordByCommunity (communityId) {
    const q = this.defaultQuery()
    q.equalTo('_id', communityId)
    const rets = await this.fetchListWithQuery<Sprints>('community', q)
    if (rets.length === 1) {
      return rets[0].categories || []
    } else {
      return []
    }
  }

  public fetchCustomCategoryGroupByCommunity (communityId) {
    return this.fetchListWithQuery<Sprints>('categoryGroup', this.queryByCommunity(communityId))
  }

  public async fetchCustomCategoriesByCommunity (communityId) {
    return this.fetchListWithQuery<Sprints>('category', this.queryByCommunity(communityId))
  }

  public fetchTasks (communityId): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.bend) {
        reject()
      }
      const q = this.queryByCommunity(communityId)
      this.dataStoreFind('task', q).then(res => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  public exportSprints (settings): Promise<any> {
    return new Promise((resolve, reject) => {
      const q = this.querySortedByName()
      this.paginateQueryResult(q, settings.searchTerm)
      q.notEqualTo('deleted', true)
      q.descending('_bmd.createdAt')
      this.filterQueryByCommunity(q, settings)
      q.fields(['name', 'email'])

      this.userFind(q).then(res => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  public exportUsers (settings): Promise<any> {
    return new Promise((resolve, reject) => {
      const qq = this.usersQuery(settings.searchTerm)
      qq.notEqualTo('deleted', true)
      qq.descending('_bmd.createdAt')
      this.filterQueryByCommunity(qq, settings)
            // qq.fields(['name', 'email'])

      this.userFind(qq).then(users => {
        resolve(users)
      }, err => {
        reject(err)
      })
    })
  }

    /* CRUD block */

  public manageItems (category) {
    const self = this
    return {
      complete (item) {
        return new Promise((resolve, reject) => {
            self.dataStoreUpdate(category, item).then(res => {
                resolve(res)
              }, (err) => {
                reject(err)
              })
          })
      },

      incomplete (item) {
        return new Promise((resolve, reject) => {
            self.dataStoreUpdate(category, item).then(res => {
                resolve(res)
              }, (err) => {
                reject(err)
              })
          })
      },

      edit (item) {
        return new Promise((resolve, reject) => {
            self.dataStoreUpdate(category, item).then(res => {
                resolve(res)
              }, (err) => {
                reject(err)
              })
          })
      },

      add (item) {
        return new Promise((resolve, reject) => {
            self.dataStoreSave(category, item).then((res) => {
                resolve(res)
              }, (err) => {
                reject(err)
              })
          })
      },

      delete (id) {
        return new Promise((resolve, reject) => {
            self.dataStoreGet(category, id).then(res => {
                res.deleted = true
                self.dataStoreUpdate(category, res).then(() => {
                    resolve(res)
                  }, (err) => {
                    reject(err)
                  })
              }, (err) => {
                reject(err)
              })
          })
      }
    }
  }

  public readMore (settings, userIds): Promise<any> {
    return new Promise((resolve, reject) => {
      const query = this.defaultQuery()

      query.contains('_id', userIds.slice(0, settings.currentPage * settings.itemsPerPage))
      query.descending('_bmd.startedAt')

      this.userFind(query, {
        relations: {
            avatar: 'BendFile'
          }
      }).then(res => {
          resolve(res)
        }, (err) => {
            reject(err)
          })
    })
  }

  public async getActiveUsersAndTotalPointsBySprint (communityId, startDate, endDate) {
    const q = this.queryByCommunity(communityId)
    let endTime
    const startTime = new Date(startDate.replace(/-/g, '/')).getTime() * 1000000
    q.greaterThanOrEqualTo('_bmd.createdAt', startTime)

    if (endTime) {
      endTime = new Date(endDate.replace(/-/g, '/') + ' 23:59:59').getTime() * 1000000
      q.lessThanOrEqualTo('_bmd.createdAt', endTime)
    }

    q.exists('points', true)
    let usersArr: string[] = []
    let count = 0
    let totalPoints = 0
    const activities: any = await this.dataStoreFind('activity', q)
        // build usersArr with user ids and totalpoints with all activity points
    activities.map((activity) => {
      const userId: string = _.get(activity, 'user._id')
      if (usersArr.indexOf(userId) === -1) {
        usersArr.push(userId)
      }
      totalPoints += activity.points
    })
    count = usersArr.length

    return { count, totalPoints }
  }

    // activity
  public activitiesPageQuery (settings: {
    after: string,
    before: string,
    searchTerm: string,
    communityId: string,
    itemsPerPage: number,
    currentPage: number,
    type: string,
    teamId: string
  }) {
    const searchTerm = settings.searchTerm

    let q = new this.bend.Query()
    if (searchTerm) {
      q.matches('summary', searchTerm, {
        ignoreCase: true
      })
    }

    const after = settings.after
    const before = settings.before
    if (after && after !== '') {
      const afterTs = new Date(after).getTime() * 1000000 // x 1 mil to equal bend time
      q.greaterThan('_bmd.createdAt', afterTs)
    }
    if (before && before !== '') {
      const beforeTs = new Date(before).getTime() * 1000000 // x 1 mil to equal bend time
      q.lessThan('_bmd.createdAt', beforeTs)
    }
    q.notEqualTo('deleted', true)
    q.descending('_bmd.createdAt')
    q.limit(settings.itemsPerPage)
    q.skip((settings.currentPage - 1) * settings.itemsPerPage)
    if (settings.communityId) {
      q.equalTo('community._id', settings.communityId)
    }
        // for admins only show activity from their community
    if (this.user.community.admin) {
      q.equalTo('community._id', this.user.community.id)
    }
    if (settings.teamId) {
      q.contains('teams', [settings.teamId])
    }

    if (settings.type) {
      q.equalTo('type', settings.type)
    }

    return q
  }

    // currently only using this for events, using activityCount key instead for other types
  public activityCountQuery (settings) {
    let q = new this.bend.Query()
        // for admins only show activity from their community
    if (this.user.community.admin) {
      q.equalTo('community._id', this.user.community.id)
    }

    q.equalTo('type', 'event-checkin').or().equalTo('type', 'event-registration')
    q.notEqualTo('deleted', true)
        // once we're ready to add team targetting to this:
        // if (settings.teamId) {
        //   q.contains('teams', [settings.teamId])
        // }
    return this.bend.DataStore.find('activity', q)
  }

  public fetchActivitiesPage (settings: {
    after: string,
    before: string,
    searchTerm: string,
    communityId: string,
    itemsPerPage: number,
    currentPage: number,
    type: string,
    teamId: string
  }) {
    return this.bend.DataStore.find('activity', this.activitiesPageQuery(settings), {
      relations: {
        user: 'user',
        community: 'community'
      }
    })
  }

  public activitiesPageNavigation (settings: {
    after: string,
    before: string,
    searchTerm: string,
    communityId: string,
    itemsPerPage: number,
    currentPage: number,
    type: string,
    teamId: string
  }) {
    return this.bend.DataStore.count('activity', this.activitiesPageQuery(settings))
  }

  public getActivity (id) {
    const params = {
      relations: {
        activity: ['action', 'business', 'event', 'volunteer_opportunity'],
        community: 'community',
        user: 'user'
      }
    }
    return this.bend.DataStore.get('activity', id, params)
  }

  public createActivity (data, communityAdmin = getActiveUser().communityAdmin) {
    if (communityAdmin) {
      return this.bend.execute('saveActivityForCommunityAdmin', data)
    } else {
      return this.bend.DataStore.save('activity', data)
    }
  }

  public updateActivity2 (data, communityAdmin = getActiveUser().communityAdmin) {
    if (communityAdmin) {
      return this.bend.execute('saveActivityForCommunityAdmin', data)
    } else {
      return this.bend.DataStore.update('activity', data)
    }
  }

    // public deleteActivity (id) {
    //   const query = {
    //     id
    //   }
    //   return this.bend.execute('removeActivityByAdmin', query)
    // }

  public usersActivityTabListQuery2 (settings: {
    activityId: string,
    communityId: string,
    type: string,
    isCanceled: boolean,
    itemsPerPage: number,
    currentPage: number
  }) {
    const activityId = settings.activityId

    let q = new this.bend.Query()

    if (settings.type) {
      q.equalTo('type', settings.type)
    }
    if (activityId) {
      q.equalTo('activity._id', settings.activityId)
    }
    if (settings.communityId) {
      q.equalTo('community._id', settings.communityId)
    }
    if (settings.isCanceled) {
      q.equalTo('deleted', true)
    } else {
      q.notEqualTo('deleted', true)
    }
    q.descending('_bmd.createdAt')

        //q.equalTo('type', 'event-registration').or().equalTo('type', 'event-checkin').or().equalTo('type', 'action').or().equalTo('type', 'volunteer_opportunity').or().equalTo('type', 'business')
        //q.equalTo('activity._id', settings.activityId)
    if (!settings.isCanceled) {
      q.limit(settings.itemsPerPage)
      q.skip((settings.currentPage - 1) * settings.itemsPerPage)
    }

    return q
  }

  public getUsersActivityTabList (settings) {
    return this.bend.DataStore.find('activity', this.usersActivityTabListQuery2(settings), {
      relations: {
        user: 'user',
        community: 'community'
      }
    })
  }

  public getUsersActivityTabListNavigation (settings) {
    return this.bend.DataStore.count('activity', this.usersActivityTabListQuery2(settings))
  }

  public getGoalActivity(id) {
	return this.bend.execute('getGoalActivity', {goalId : id})
  }

  public fetchImagesById (settings: { id: string[] }) {
    const query = new this.bend.Query()
    query.contains('_id', settings.id)
    return this.bend.File.find(query)
  }

  public refreshActivities (settings: { keyword: string, type: string }) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    if (settings.keyword !== '') {
      query.matches('name', new RegExp(settings.keyword + '+', 'gi'))
    }
    query.ascending('name')
    query.limit(30)

    return this.bend.DataStore.find(settings.type, query)
  }

  public usersActivityTabListQuery (settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    const q = new this.bend.Query()
    q.notEqualTo('deleted', true)
    q.descending('_bmd.createdAt')
    q.equalTo('user._id', settings.userId)
    q.limit(settings.itemsPerPage)
    q.skip((settings.currentPage - 1) * settings.itemsPerPage)

    return q
  }

  public fetchUsersActivityTabList (settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    return this.bend.DataStore.find('activity', this.usersActivityTabListQuery(settings))
  }

  public fetchUsersActivityTabListNavigation (settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    return this.bend.DataStore.count('activity', this.usersActivityTabListQuery(settings))
  }

  public fetchUsersGoalsTabList (settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    return this.bend.execute('getUserGoals', settings); 

  }

  public usersCountPushTemplates (settings: {
    audiences: Array<any>,
    defaultQuery: object,
    userQuery: string
  }): Promise<any> {
    let filter = JSON.parse(settings.userQuery)
    let query = new this.bend.Query({ filter })
    query.notEqualTo('deleted', true).equalTo('enabled', true)

    let qq = {}
    if (settings.audiences.length > 0) {
      filter = _.clone(_.get(settings, '[0].query'))
      qq = new this.bend.Query({ filter })
      _.map(settings.audiences, function (o, idx) {
        if (idx > 0) {
            filter = _.clone(_.get(o, 'query'))
                        ; (qq as any).or({ filter })
          }
      })
    } else {
      filter = _.clone(settings.defaultQuery)
      qq = new this.bend.Query({ filter })
    }

    query.and(qq)

    return this.bend.DataStore.count('user', query)
  }

  public async updatePushNotification (data, communityAdmin = getActiveUser().communityAdmin) {
    const newData = _.clone(data)
    delete newData.$$hashKey
    if (newData.params) {
      newData.params.map(function (o) {
        delete o.$$hashKey
      })
    }

    if (communityAdmin) {
      return this.bend.execute('savePushForCommunityAdmin', newData)
    } else if (newData._id) {
      return BendTable.update('push', newData)
    } else {
      return BendTable.create('push', newData)
    }
  }

  public fetchLinkedPushNotification (settings: { eventId: string }) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('event._id', settings.eventId)
    return this.bend.DataStore.find('push', query)
  }

  public fetchLinkedPushNotificationByPollQuestion (settings: { pollQuestionId: string }) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('pollQuestion._id', settings.pollQuestionId) // association with this poll
    return this.bend.DataStore.find('push', query)
  }

  public fetchLinkedPushNotificationBySurvey (surveyId: string) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('survey._id', surveyId) // association with this survey
    return this.bend.DataStore.find('push', query)
  }

  public fetchLinkedPushNotificationByChallenge (settings: { challengeId: string }) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('challenge._id', settings.challengeId) // association with this poll
    return this.bend.DataStore.find('push', query)
  }

  public fetchPushNotificationAudiences (): Promise<any> {
    let userObj
    if (getActiveUser().communityAdmin) {
      userObj = { user: getActiveUser()._id }
    } else {
      userObj = { user: null }
    }
    return this.bend.execute('get-push-audiences', userObj)
  }

  public async fetchPushNotificationDeeplinks () {
    let user = await getActiveUser()
    let community = await this.bend.DataStore.get('community', user.community._id)
    if (!community.appName) {
      console.log('COMMUNITY HAS NO APP NAME, USING MILKCRATE')
      community.appName = 'milkcrate'
    }
    let appName = community.appName.toLowerCase()
    let query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('community._id', user.community._id)
    let allDeeplinks: Array<any> = []

        // code block below repeats because deeplink needs to be different, could also put this in a fn in helper
        // let categories = await this.dataStoreFind('category', query)
    let categories = await BendTable.getList(BendTable.Tables.CATEGORY)
    categories.forEach(cat => {
      allDeeplinks.push({
        name: `Category - ${cat.name}`,
        deeplink: `${appName}://search/${cat.slug}`
      })
    })
    let places = await this.dataStoreFind('business', query)
    places.forEach(place => {
      allDeeplinks.push({
        name: `Place - ${place.name}`,
        deeplink: `${appName}://businesses/${place._id}`
      })
    })
    let actions = await this.dataStoreFind('action', query)
    actions.forEach(action => {
      allDeeplinks.push({
        name: `Action - ${action.name}`,
        deeplink: `${appName}://actions/${action._id}`
      })
    })
    let events = await this.dataStoreFind('event', query)
    events.forEach(event => {
      allDeeplinks.push({
        name: `Event - ${event.name}`,
        deeplink: `${appName}://events/${event._id}`
      })
    })
    let surveys = await this.dataStoreFind('survey', query)
    surveys.forEach(survey => {
      allDeeplinks.push({
        name: `Survey - ${survey.name}`,
        deeplink: `${appName}://surveys/${survey._id}`
      })
    })
    let volunteerOpps = await this.dataStoreFind('volunteer_opportunity', query)
    volunteerOpps.forEach(volunteer => {
      allDeeplinks.push({
        name: `Volunteer Opportunity - ${volunteer.name}`,
        deeplink: `${appName}://volunteer_opportunities/${volunteer._id}`
      })
    })
    let goals = await this.dataStoreFind('goals', query)
    goals.forEach(goal => {
      allDeeplinks.push({
        name: `Goal - ${goal.title}`,
        deeplink: `${appName}://goals/${goal._id}`
      })
    })
    allDeeplinks.sort((a, b) => {
      let nameA = a.name.toUpperCase()
      let nameB = b.name.toUpperCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
    return allDeeplinks
  }

  public saveAndSendPushNotification (data): Promise<any> {
    return this.bend.execute('save-and-send-notification', data)
  }

  public sendScheduledPushNotification (): Promise<any> {
    return this.bend.execute('send-scheduled-notifications')
  }

  public usersCountPushNotifications (settings: {
    audiences: Array<any>,
    defaultQuery: object,
    userQuery: string
  }) {
    let filter = JSON.parse(settings.userQuery)
    let query = new this.bend.Query({ filter })
    query.notEqualTo('deleted', true).equalTo('enabled', true)

    let qq = {}
    if (settings.audiences.length > 0) {
      let audienceQuery: any = _.get(settings.audiences, '[0].query')
      filter = _.clone(audienceQuery)
      qq = new this.bend.Query({ filter })
      _.forEach(settings.audiences, function (o, idx) {
        if (idx > 0) {
            let filter = _.clone(o.query)
              ; (qq as any).or({ filter })
          }
      })
    } else {
      filter = _.clone(settings.defaultQuery)
      qq = new this.bend.Query({ filter })
    }

    query.and(qq)
    return this.bend.DataStore.count('user', query)
  }

    // categories

  public getCategoryGroupList (communityId) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('community._id', communityId)
    query.ascending('name')

    const params = {
      relations: {
        innerIcon: 'BendFile',
        outerIcon: 'BendFile',
        community: 'community'
      }
    }

    return this.bend.DataStore.find('categoryGroup', query, params)
  }

    // utilities
  public getUtilityUncodedBusinessCount (): Promise<any> {
    let q = new this.bend.Query()
    q.notEqualTo('deleted', true)
    q.exists('_geoloc', false)
    q.exists('address1', true)
    q.exists('city', true)
    q.exists('state', true)

    return this.bend.DataStore.count('business', q)
  }

  public getUtilityBusinesses (): Promise<any> {
    let q = new this.bend.Query()
    q.notEqualTo('deleted', true)
    q.exists('_geoloc', false)
    q.exists('address1', true)
    q.exists('city', true)
    q.exists('state', true)
    q.limit(100)

    return this.bend.DataStore.find('business', q)
  }

    // users
  public async init () {
    const init = new Promise((resolve, reject) => {
      const promise = this.bend.init({
        appKey: this.CREDENTIALS.APP_KEY,
        appSecret: this.CREDENTIALS.APP_SECRET
      })
      resolve(promise)
    })
    try {
      const response = await init
      this.bend.setActiveUser(response)
      const user = getActiveUser()
      this.user = {
        community: {
            id: _.get(user, 'community._id'),
            admin: _.get(user, 'communityAdmin')
          }
      }
    } catch (err) {
      this.user = {
        community: {
            id: '',
            admin: false
          }
      }
      this.bend.User.logOut()
    }
  }

  public login (credentials) {
    return this.bend.User.login(credentials)
  }

  public setActiveUser (userData) {
    this.bend.setActiveUser(userData)
  }

  public getAvatar (): Promise<any> {
    const user = getActiveUser()
    const avatarId = _.get(user, 'avatar._id')

    let query = new this.bend.Query()
    query.equalTo('_id', avatarId)
    return this.bend.File.find(query)
  }

  public async updateUser (user, communityAdmin = getActiveUser().communityAdmin) {
    const userData = _.clone(user)
    delete userData.$$hashKey
    delete userData.passwordConfirm

    let response
    if (communityAdmin) {
      response = await this.bend.execute('updateUser', userData)
    } else {
      response = await this.bend.User.update(userData)
    }
    return response

  }

  public deleteUser (id) {
    const query = {
      userId: id
    }
    return this.bend.execute('deleteUser', query)
  }

  public getUserAddress (userId) {
    const query = new this.bend.Query()
    query.equalTo('user._id', userId)

    return this.bend.DataStore.find('userAddress', query)
  }

    // surveyQuestion survey

  public async getSurveyQuestionAndResponseCount (surveyArr) {
    return new Promise((resolve, reject) => {
      try {
        let surveyIds = surveyArr.map(s => s._id)
        const pollQ = this.defaultQuery()

        pollQ.contains('survey._id', surveyIds)

        this.bend.DataStore.find('pollQuestion', pollQ).then(questions => {

            const surveyResponseQ = this.defaultQuery()

            surveyResponseQ.contains('survey._id', surveyIds)
            this.bend.DataStore.find('surveyResponse', surveyResponseQ).then(responses => {
                resolve({ questions, responses })
              })
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  public async getSurveyActivities (settings) {
    const category = 'pollQuestion' // surveys use the same dbs as polls
    const q = this.questionsQuery(settings)
    q.equalTo('activity._collection', 'survey') // return survey completions only
    const surveyActivity = await this.fetchListWithQuery(category, q)
    return surveyActivity
  }

  public getSurveyResponses (survey) {
    return new Promise((resolve, reject) => {
      try {
        let query = this.defaultQuery()

        query.equalTo('survey._id', survey._id)
        let responseCount = 0
        this.bend.DataStore.find('pollQuestion', query).then(questions => {

            let pollQuestionAnswerQ = this.defaultQuery()
            let pollQuestionIds = questions.map(ques => ques._id)
            pollQuestionAnswerQ.contains('question._id', pollQuestionIds)

            this.bend.DataStore.find('pollQuestionAnswer', pollQuestionAnswerQ).then(answers => {

                survey.questions = questions
                answers.map(ans => {
                    survey.questions.map(quest => {
                        if (ans.question._id === quest._id) {
                            if (!quest.answers || quest.answers.length === 0) quest.answers = [ans]
                            else quest.answers.push(ans)
                          }
                      })
                  })
                this.bend.DataStore.find('surveyResponse', query).then(responses => {

                    responseCount = responses.length
                    let surveyResponses: any = []
                    survey.questions.map(q => {
                        let questionAndAnswers: any = {
                            question: q.question,
                            userResponses: [],
                            answers: []
                                    // responses: []
                          }
                        responses.map(response => { // user responses
                            if (survey._id === response.survey._id) {
                                q.answers.map(answer => { // survey question answers
                                    response.answers.map(userResponse => { // look at ids of answers that user chose
                                                //   if (questionAndAnswers.answers.indexOf(answer.title) === -1) {
                                                //     questionAndAnswers.responses.push(0)
                                                //   }
                                                //   if (answer._id === userResponse) questionAndAnswers.answers[questionAndAnswers.indexOf(answer.title)] += 1
                                        if (questionAndAnswers.answers.indexOf(answer.title) === -1) {
                                            questionAndAnswers.answers.push(answer.title)
                                                    // questionAndAnswers.userResponses[answer.title] = 0
                                            questionAndAnswers.userResponses.push({ title: answer.title, count: 0 })
                                          }
                                                // if (answer._id === userResponse) questionAndAnswers.userResponses[answer.title] += 1
                                        let userResponseIdx = questionAndAnswers.answers.indexOf(answer.title)
                                        if (answer._id === userResponse) questionAndAnswers.userResponses[userResponseIdx].count += 1
                                      })
                                  })
                              }
                          })
                        surveyResponses.push({ survey: survey._id, questionAndAnswers })
                      })

                    resolve({ surveyResponses, responseCount })
                  })
              })
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  public getResponseList (questionId, communityAdmin = getActiveUser().communityAdmin) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.descending('_bmd.createdAt')
    query.equalTo('question._id', questionId)
    query.exists('community', true)

    if (communityAdmin) {
      query.equalTo('community._id', getActiveUser().community._id)
    }

    const params = {
      relations: {
        user: 'user',
        answer: 'pollQuestionAnswer',
        question: 'pollQuestion'
      }
    }

    return this.bend.DataStore.find('pollQuestionResponse', query, params)
  }

    // survey names getter
  public async getCommunitySurveyNames () {
    const user = getActiveUser()
    const communityId = user.community._id
    let query = new this.bend.Query()
    query.equalTo('community._id', communityId)
    query.exists('survey', true)
    query.notEqualTo('deleted', true)
    const surveyPolls = await this.bend.DataStore.find('pollQuestion', query)
    let surveyNames: Array<any> = []
    surveyPolls.map(poll => {
      if (poll.survey && poll.survey.length > 0) {
        poll.survey.map(name => {
            if (surveyNames.indexOf(name) === -1) surveyNames.push(name)
          })
      }
    })

    return surveyNames
  }

    // tag control
  public async getCommunityTags () {
    const user = getActiveUser()
    const communityId = user.community._id
    let query = new this.bend.Query()
    query.equalTo('community._id', communityId)
    query.notEqualTo('deleted', true)

    const places = await this.bend.DataStore.find('business', query)
    const events = await this.bend.DataStore.find('event', query)
    const actions = await this.bend.DataStore.find('action', query)

    let tags: Array<string> = []

    places.map(function (place) {
      if (place.tags && place.tags.length) {
        place.tags.map(function (tag) {
            if (tags.indexOf(tag) === -1) {
                tags.push(tag)
              }
          })
      }
    })
    events.map(function (event) {
      if (event.tags && event.tags.length) {
        event.tags.map(function (tag) {
            if (tags.indexOf(tag) === -1) {
                tags.push(tag)
              }
          })
      }
    })
    actions.map(function (action) {
      if (action.tags && action.tags.length) {
        action.tags.map(function (tag) {
            if (tags.indexOf(tag) === -1) {
                tags.push(tag)
              }
          })
      }
    })

    return tags
  }

    // comments
  public async fetchComments (settings) {
    let comments = await this.bend.DataStore.find('comment', this.commentsTabQuery(settings), {
      relations: {
        user: 'user',
        photo: 'BendFile'
      }
    })
    if (settings.subjectType === 'business') {
      let placeRatings = await this.bend.DataStore.find('activity', this.commentsTabQuery(settings), {
        relations: {
            user: 'user',
            photo: 'BendFile'
          }
      })
      placeRatings = placeRatings.filter(rating => {
        if (rating.summary && rating.summary.indexOf('Comment on ') !== -1) return true
        else return false
      })
      comments = comments.concat(placeRatings)
    }
    return comments
  }

  public commentsTabQuery (settings) {
    const q = new this.bend.Query()
    q.notEqualTo('deleted', true)
    q.descending('_bmd.createdAt')
    q.equalTo('activity._id', settings.subject._id)
    return q
  }

  public fetchSubjectByType (id, type) {
    return this.bend.DataStore.get(type, id)
  }

  public async deleteComment (id) {
    const comment = await this.bend.DataStore.get('comment', id)
    comment.deleted = true
    return this.updateComment(comment)
  }

  public updateComment (data, communityAdmin = getActiveUser().communityAdmin) {
    if (communityAdmin) {
      return this.bend.execute('saveDataForCommunityAdmin', {
        type: 'comment',
        data: data
      })
    } else {
      return this.bend.DataStore.update('comment', data)
    }
  }

    // teams
  public getMultipleTeams (ids: Array<string>) {
    let query = this.defaultQuery()
    query.contains('id', ids)
    return this.dataStoreFind('team', query)
  }

  public getAllTeams (): Promise<any> {
    let query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.ascending('position')
    return new Promise((resolve, reject) => {
      this.bend.DataStore.find('team', query).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  public getTeamPoints = async (type = 'points', communityId, teamId = null, sprint) => {

    let query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('community._id', communityId)
    query.exists(type, true)

    if (sprint != null && typeof sprint === 'object' && sprint._id && sprint._id.length) {
      const startTime = new Date(sprint.startDate.replace(/-/g, '/'))
                .getTime() * 1000000
      const endTime = new Date(sprint.endDate.replace(/-/g, '/') + ' 23:59:59')
                .getTime() * 1000000
      query.greaterThanOrEqualTo('_bmd.createdAt', startTime)
      query.lessThanOrEqualTo('_bmd.createdAt', endTime)
    }

    if (teamId)
      query.contains('teams', [teamId])

    const aggregation = this.bend.Group.sum(type)
    aggregation.by('user._id')
    aggregation.query(query)

    const resp = await this.bend.DataStore.group('activity', aggregation)
        //const resp = await this.bend.DataStore.find('activity', query);

    return resp
  }

  public async getCurrentSprint (communityId) {
        // get currentSprint
    let sprintQ = new this.bend.Query()
    sprintQ.equalTo('community._id', communityId)
    sprintQ.notEqualTo('deleted', true)
    sprintQ.greaterThanOrEqualTo('endDate', moment().format('YYYY-MM-DD'))
    sprintQ.lessThanOrEqualTo('startDate', moment().format('YYYY-MM-DD'))
    try {
      const sprint = await this.bend.DataStore.find('sprint', sprintQ)
      if (sprint && sprint[0] && sprint[0]._id) {
        return sprint[0]
      } else return null
    } catch (error) {
      return null
    }
  }

    // collections
  public getCollection (id) {
    return this.bend.DataStore.get('collection', id)
  }

  public getCollectionEnabled (communityAdmin = getActiveUser().communityAdmin) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('enabled', true)
    query.ascending('name')

    if (communityAdmin) {
            // query.and(new $bend.Query().equalTo('community._id', Bend.getActiveUser().community._id).or().exists('community', false))
      query.and(new this.bend.Query().equalTo('community._id', getActiveUser().community._id))
    }

    return this.bend.DataStore.find('collection', query)
  }

  public createCollection (data, communityAdmin = getActiveUser().communityAdmin) {
    if (communityAdmin) {
      return this.bend.execute('saveDataForCommunityAdmin', {
        type: 'collection',
        data: data
      })
    } else {
      return this.bend.DataStore.save('collection', data)
    }
  }

  public async updateCollection (data, communityAdmin = getActiveUser().communityAdmin) {
    if (communityAdmin) {
      return this.bend.execute('saveDataForCommunityAdmin', {
        type: 'collection',
        data: data
      })
    } else {
      return this.bend.DataStore.update('collection', data)
    }
  }

  public async deleteCollection (id) {
    const collection = await this.getCollection(id)
    collection.deleted = true
    return this.updateCollection(collection)
  }

    // taks
  public getTaskList (communityId) {
    const query = new this.bend.Query()
    query.equalTo('community._id', communityId)
    query.notEqualTo('deleted', true)
    return this.bend.DataStore.find('task', query)
  }

    // category
  public async getCategoryList (communityId = null, params: any = {}, user = getActiveUser()) { // SEE ABOUT ADDING COMMUNITY ID HERE TO QUERY IF NOT MILKCRATE USER - TODO
    let community: any = {}
    if (communityId) community = await this.bendTable.get(BendTable.Tables.COMMUNITY, communityId)
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.ascending('name')

    if (user.communityAdmin && community.usesMilkCrateCategories
            // (user.community._id === '58dec3004bad30145b037541' || // milkcrate
            // user.community._id === 'P1y1Y0UgSGWEU-jlvGqNJg' || // temple
            // user.community._id === 'qdED8Q1EScmv8cHGf0EDUA') // FPC
        ) { // milkcrate and temple use legacy milkcrate categories
      query.exists('community', false)
    } else if (user.communityAdmin) { // if community admin but not at milkcrate or temple
      query.equalTo('community._id', user.community._id)
    } else if (communityId) {
        query.equalTo('community._id', communityId)
      }

    let relations: any = {
      relations: {
        coverImage: 'BendFile',
        image1: 'BendFile',
        image2: 'BendFile',
        image3: 'BendFile',
        image4: 'BendFile'
      }
    }

    if (params && params.relations) {
      relations.relations.community = 'community'
    }

    return this.bend.DataStore.find('category', query, relations)
  }

  public getCategoryEnabledList () {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('enabled', true)
    query.ascending('name')

    return this.bend.DataStore.find('category', query)
  }

  public getGlobalCategoryEnabledList () {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('enabled', true)
    query.exists('community', false)
    query.ascending('name')

    return this.bend.DataStore.find('category', query)
  }

  public async createUser (data, communityAdmin = getActiveUser().communityAdmin) {
    const userData = _.clone(data)
        // const error = { name: 'RequestError' }

    delete userData.passwordConfirm

    if (communityAdmin) {
      return this.bend.execute('createUser', userData)
    } else {
      const createdUser = await this.bend.User.create(userData, {
        state: false
      })
      return this.bend.DataStore.save('event', {
        type: 'createuser',
        user: createdUser._id,
        date: commonUtil.getToday()
      })
    }
  }

    // communities
  public getCommunityEnabledList () {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('enabled', true)
    query.ascending('name')

    return this.bend.DataStore.find('community', query)
  }

  public getCommunitiesByStatus (status: string) {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.ascending('name')

    if (status.length) {
      query.equalTo('status', status)
    }

    return this.bend.DataStore.find('community', query, {
      relations: {
        logo: 'BendFile'
      }
    })
  }

  public fetchWhiteListedEmails (communityId) {
    const query = new this.bend.Query()
    query.equalTo('community', communityId)
    query.notEqualTo('deleted', true)
    query.ascending('email')
    return this.bend.DataStore.find('whitelisted', query)
  }

  public addWhiteListedEmails (data) {
    return this.bend.execute('addToWhitelist', data)
  }

  public upadteWhiteListedEmail (data) {
    return this.bend.DataStore.update('whitelisted', data)
  }

  public getEvents (data = {}) {
    return this.bend.execute('getEvents', data)
  }

  public getEventCheckins (eventId = null, instanceId) {
    if (eventId == null) return []

    let query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.equalTo('type', 'event-checkin')
    query.and(new this.bend.Query().equalTo('activity._id', eventId).or().equalTo('parentId', eventId))

    if (instanceId != null) {
      query.equalTo('instanceId', parseInt(instanceId))
    }

    return this.bend.DataStore.find('activity', query, {
      relations: {
        user: 'user',
        'user.avatar': 'BendFile'
      }
    })
  }

  public async getEventCancellations (eventId = null, instanceId) {

    let cancellations = []
    let registrations = [] //Registrations that were deleted

    if (eventId == null) return []

    let query = new this.bend.Query()
    //query.notEqualTo('deleted', true) https://airtable.com/tblX3rxpt1ugTg2kT/viwVsS8XZH56NFMXT/recja6pa6iP2hE3Vq?blocks=hide
    //In order to show cancellations of people who might have registered again after cancel the registration, we need to show deleted cancellations too
    query.equalTo('type', 'event-cancellation')
    query.notEqualTo('deleted', true)
    query.and(new this.bend.Query().equalTo('activity._id', eventId).or().equalTo('parentId', eventId))

    if (instanceId != null) {
      query.equalTo('instanceId', parseInt(instanceId))
    }

    cancellations = await this.bend.DataStore.find('activity', query, {
      relations: {
        user: 'user',
        'user.avatar': 'BendFile'
      }
    })

    let query2 = new this.bend.Query()
    query2.equalTo('type', 'event-registration')
    query2.equalTo('deleted', true)
    query2.and(new this.bend.Query().equalTo('activity._id', eventId).or().equalTo('parentId', eventId))
    query2.and(new this.bend.Query().equalTo('instanceId', instanceId).equalTo("registerAll", false).or().equalTo('registerAll', true))

    registrations = await this.bend.DataStore.find('activity', query2, {
      relations: {
        user: 'user',
        'user.avatar': 'BendFile'
      }
    })

    return cancellations.concat(registrations)

  }

  public deleteFollowingEvents = async (parentId, fromDate) => {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.greaterThan('startsAtUTC', fromDate)
    query.equalTo('parentId', parentId)
    const events = await this.bend.DataStore.find('event', query)
    asyncForEach(events, async (event) => {
      event.deleted = true
      await this.bend.DataStore.update('event', event)
    })
  }

  public toggleEvent = async (eventId, enabled) => {
    const query = new this.bend.Query()
    query.equalTo('parentId', eventId).or().equalTo('_id', eventId)
    const events = await this.bend.DataStore.find('event', query)
    asyncForEach(events, async (event) => {
      event.enabled = enabled
      await this.bend.DataStore.update('event', event)
    })
  }

    // file
  public upload (file, callback, ext, progressCallback) {
    const obj = {
      _filename: file.name,
      size: file.size,
      mimeType: file.type
    }

    if (ext) {
      _.extend(obj, ext)
    }

    this.bend.File.upload(file, obj, ({ 'public': true }), function (res) {
      callback(null, res)
    }, function (total, prog) {
      if (progressCallback) {
          progressCallback(total, prog)
        }
    }).then(function (res) {
            // callback(null, res);
      }, function (error) {
          callback(error)
        })
  }

  public getFile (refObj, callback) {
    if (refObj) {
      const query = new this.bend.Query()
      query.equalTo('_id', refObj._id)
      this.bend.File.find(query).then(function (rets) {
        callback(rets[0])
      })
    } else {
      callback(refObj)
    }
  }

  public chartQuery (chartObj, communityId) {
    let query = this.defaultQuery()
    let { table, value } = chartObj.data
    let key = 'type'

        // tables below are not directly linked to communities
    if (table !== 'comment' || table !== 'businessRating') query.equalTo('community._id', communityId)
    if (key && value) {
      if (Array.isArray(key)) { // if key is array of keys
        query.or().exists(key[0], value) // pull all records that have a value for at least one key
        key.map((k, idx) => {
            if (idx > 0) query.or().exists(k, value)
          })
      } else { // if key isn't an array
        if (Array.isArray(value)) { // if value is array of potential values
            query.and().equalTo(key, value[0]) // find equal to first value
            value.map((val, idx) => {
                if (idx > 0) query.or().equalTo(key, val) // and equal to all others
              })
          } else {
            query.equalTo(key, value) // standard, simple
          }
      }
    }
    if (chartObj.evergreen) { // evergreen charts show the last month of data
      let now = moment()
            // let endDate = now
      let startDate = (now.valueOf() - (31 * 86400000)) * 1000000
            // query.greaterThan('_bmd.createdAt', startDate) // no ands
      query.and().greaterThan('_bmd.createdAt', startDate) // query was getting duplicated, and inside an and, this seemed to work for location checkins
            // query.and().lessThan('_bmd.createdAt', endDate)
    } else {
      if (chartObj.startDate && chartObj.startDate !== 'Invalid date') query.greaterThan('_bmd.createdAt', moment(chartObj.startDate).valueOf() * 1000000)
      if (chartObj.endDate && chartObj.endDate !== 'Invalid date') query.lessThan('_bmd.createdAt', moment(chartObj.endDate).endOf('day').valueOf() * 1000000)
    }

    if (chartObj.teams && chartObj.teams.length > 0) {
      let teamIds
      if (typeof chartObj.teams[0] === 'string') teamIds = chartObj.teams
      else teamIds = chartObj.teams.map(team => team._id)
      query.contains('teams', teamIds)
    }
    if (chartObj.segments && chartObj.segments.length > 0) {
      let segmentIds
      if (typeof chartObj.segments[0] === 'string') segmentIds = chartObj.segments
      else segmentIds = chartObj.teams.map(segment => segment._id)
      query.contains('segments', segmentIds)
    }
    if (chartObj.places && chartObj.places.length !== 0) {
      let placeIds = chartObj.places.map(place => place._id)
      query.contains('activity._id', placeIds)
    }
    return query
  }

  public async getChartData (chartObj, communityId) {
    if (chartObj.data.endpoint) {
      return this.bend.execute(chartObj.data.endpoint, { communityId, chartObj })
    }

    let table = chartObj.table
    if (table === 'user' && chartObj.data.code.indexOf('demographic') !== -1) { // using chartObj.data.code as a way to indicate what type of chart is being requested. far from perfect
      return this.bend.execute('getCommunityDemographics', {
        community: communityId
      })
    }

    let query = this.chartQuery(chartObj, communityId)
    if (table === 'user') {
      return this.userFind(query)
    } else {
      return this.dataStoreFind(table, query)
    }
  }

  public async fetchSurveys (settings) {
    const category = 'pollQuestion'
    const q = this.questionsQuery(settings)
    q.exists('survey', true) // include surveys
    const surveysList = await this.fetchListWithQuery<PollQuestions>(category, q, {
      relations: {
        community: 'community'
      }
    })
    const count = surveysList.length
    return {
      surveysList,
      count
    }
  }

    // this is called but not passed parameters
  public async fetchLeaderboard (settings, communityAdmin, communityId) {
    const q = this.leaderboardQuery(communityAdmin, communityId)
    q.greaterThan('rank', 0)
    const leaderboards = await this.fetchLeaderboardList(settings, q)
    const count = await this.userCount(q)
    return {
      leaderboards,
      count
    }
  }

  public async fetchNewestUsers (settings, communityAdmin, communityId) {
    const q = this.leaderboardQuery(communityAdmin, communityId)
    const newestUsers = await this.fetchNewestUsersList(settings, q)
    const count = await this.userCount(q)
    return {
      newestUsers,
      count
    }
  }

  public async fetchPushTemplates (settings) {
    const category = 'pushTemplate'
    const q = this.querySortedByCreated()
    const user = getActiveUser()
    q.notEqualTo('deleted', true)

    this.paginateQueryResult(q, settings)
    const pushTemplatesList: any = await this.fetchListWithQuery<PushTemplates>(category, q, {
      relations: {
        community: 'community'
      }
    })

    const pushTemplates = _.filter(pushTemplatesList, template => {
      return template.obj.audience && template.obj.audience.defaultQuery.community_id === user.community._id
    })
    return pushTemplates
  }

  public async fetchEventTemplates (settings) {
    return this.bend.execute('getEventTemplates', {})
  }

  public async fetchOrganizations () {
    return this.bend.execute('getOrganizations', {})
  }

  public async isOrganizationInUse (organizationId) {
    const user = getActiveUser()
    const userCommunityId = user.community && user.community._id

    let q = this.queryByCommunity(userCommunityId)
    q.equalTo('organization', organizationId)
    q.notEqualTo('deleted', true)

    return this.bend.User.find(q).then(result => result && result.length > 0)
  }

  public async fetchSchools () {
    return this.bend.execute('getSchools', {})
  }

  public async isSchoolInUse (schoolId) {
    const user = getActiveUser()
    const userCommunityId = user.community && user.community._id

    let q = this.queryByCommunity(userCommunityId)
    q.equalTo('school', schoolId)
    q.notEqualTo('deleted', true)

    return this.bend.User.find(q).then(result => result && result.length > 0)
  }

    public async isUserGroupInUse(id) {
        const user = getActiveUser()
        const userCommunityId = user.community && user.community._id

        var q = this.queryByCommunity(userCommunityId);
        q.equalTo("userGroup._id", id)
        q.notEqualTo("deleted", true)

        return this.bend.User.find(q).then(result => result && result.length > 0)
    }

    // used for leaderboards page
  public async fetchLeaderboardUsers (settings): Promise<any> {

    return new Promise(async (resolve, reject) => {

            //console.log('fetchLeaderboardUsers')

      let result = {}

      const query = new this.bend.Query()
      this.filterQueryByCommunity(query, settings)
      query.exists('points', true)
      query.notEqualTo('deleted', true)
      query.skip((settings.currentPage - 1) * 50)
      query.limit(50)
      query.descending('result')

      if (typeof settings.sprint === 'object' &&
                settings.sprint._id &&
                settings.sprint._id.length
            ) {
        const startTime = new Date(settings.sprint.startDate.replace(/-/g, '/'))
                    .getTime() * 1000000
        const endTime = new Date(settings.sprint.endDate.replace(/-/g, '/') + ' 23:59:59')
                    .getTime() * 1000000
        query.greaterThanOrEqualTo('_bmd.createdAt', startTime)
        query.lessThanOrEqualTo('_bmd.createdAt', endTime)
      }

      if (settings.category && settings.category._id && settings.category._id.length) {
        query.containsAll('categories', [settings.category._id])
      }

      const aggregation = this.bend.Group.sum('points')
      aggregation.by('user._id')
      aggregation.query(query)
      this.bend.DataStore.group('activity', aggregation)
                .then(rets => {
                  rets.forEach(item => {
                    const id = item['user._id']
                    result[id] = {
                      _id: id,
                      points: item.result
                    }
                  })
                    //console.log('rets', rets)
                  return rets
                })
                .then(() => {
                  const query = new this.bend.Query()
                  query.contains('_id', Object.keys(result))
                  return Bend.User.find(query, {
                    relations: {
                      avatar: 'BendFile'
                    }
                  })
                })
                .then(users => {
                  users.forEach(user => {
                    result[user._id].avatar = user.avatar
                    result[user._id].email = user.email
                    result[user._id].defaultAvatar = user.defaultAvatar
                    result[user._id].name = user.name
                    result[user._id].deleted = user.deleted
                  })
                })
                .then(() => {
                  let array: string[] = []
                  for (const key in result) {
                    if (result.hasOwnProperty(key)) {
                      const element = result[key]
                      array.push(element)
                    }
                  }
                    //console.log('array', array)
                  resolve(array)
                }, err => {
                  console.error(err)
                  reject(err)
                })

    })

  }

  public async countLeaderboardUsers (settings): Promise<any> {

        //WARNING: This is counting deleted users since we are counting from activity
    return new Promise(async (resolve, reject) => {

            //console.log('countLeaderboardUsers')
            //console.log("settings", settings)

      const query = new this.bend.Query()
      this.filterQueryByCommunity(query, settings)
      query.exists('points', true)
      query.notEqualTo('deleted', true)

      if (typeof settings.sprint === 'object' &&
                settings.sprint._id &&
                settings.sprint._id.length
            ) {
        const startTime = new Date(settings.sprint.startDate.replace(/-/g, '/'))
                    .getTime() * 1000000
        const endTime = new Date(settings.sprint.endDate.replace(/-/g, '/') + ' 23:59:59')
                    .getTime() * 1000000
        query.greaterThanOrEqualTo('_bmd.createdAt', startTime)
        query.lessThanOrEqualTo('_bmd.createdAt', endTime)
      }

      if (settings.category && settings.category._id && settings.category._id.length) {
        query.containsAll('categories', [settings.category._id])
      }

      const aggregation = this.bend.Group.sum('points')
      aggregation.by('user._id')
      aggregation.query(query)
      this.bend.DataStore.group('activity', aggregation)
                .then(rets => {
                  let array = rets.map(item => item['user._id'])
                  resolve(array)
                }, err => {
                  console.error(err)
                  reject(err)
                })

    })

  }

  public async sumLeaderboardPoints (settings): Promise<any> {

    return new Promise(async (resolve, reject) => {

            //console.log('sumLeaderboardPoints')
            //console.log("settings", settings)

      const query = new this.bend.Query()
      this.filterQueryByCommunity(query, settings)
      query.exists('points', true)
      query.notEqualTo('deleted', true)

      if (typeof settings.sprint === 'object' &&
                settings.sprint._id &&
                settings.sprint._id.length
            ) {
        const startTime = new Date(settings.sprint.startDate.replace(/-/g, '/'))
                    .getTime() * 1000000
        const endTime = new Date(settings.sprint.endDate.replace(/-/g, '/') + ' 23:59:59')
                    .getTime() * 1000000
        query.greaterThanOrEqualTo('_bmd.createdAt', startTime)
        query.lessThanOrEqualTo('_bmd.createdAt', endTime)
      }

      if (settings.category && settings.category._id && settings.category._id.length) {
        query.containsAll('categories', [settings.category._id])
      }

      const aggregation = this.bend.Group.sum('points')
      aggregation.query(query)
      this.bend.DataStore.group('activity', aggregation)
                .then(rets => {
                  if (rets && rets[0] && rets[0].result) {
                    resolve(rets[0].result)
                  } else resolve(0)
                }, err => {
                  console.error(err)
                  reject(err)
                })

    })

  }

  public async fetchUsers (settings) {
    const q = this.updatedUsersQuery(settings)
    const users = await this.fetchUsersList(q)
    const usersEngagements = await this.fetchEngagements(users)
    return {
      users,
      usersEngagements
    }
  }

  public fetchUsersById (settings: { id: string[] }) {
    const q = new this.bend.Query()
    q.contains('_id', settings.id)
    return this.bend.User.find(q, {
      relations: {
        avatar: 'BendFile'
      }
    })
  }

  public async fetchAllActivities (id) {
    const q = new this.bend.Query()
    const activities = await this.dataStoreFind('activity', q)
    return activities
  }

  public async updateActivity (item) {
    return this.dataStoreUpdate('activity', item)
  }

  public async getUserCountByTeamId (id) {
    const q = this.defaultQuery()
    q.contains('teams', [id])
    return await this.bend.User.count(q)
  }

  public async fetchUsersOnly () {
    return new Promise((resolve, reject) => {
      const q = this.defaultQuery()
      q.descending('_bmd.createdAt')

      const user = getActiveUser()
      const userCommunityId = user.community && user.community._id

      if (userCommunityId) {
        q.equalTo('community._id', userCommunityId)
      }
      this.bend.User.find(q).then(function (items) {
        resolve(items)
      }, function (err) {
        reject(err)
      })
    })
  }

  public async getCommunityList () {
    const query = new this.bend.Query()
    query.notEqualTo('deleted', true)
    query.ascending('name')

    const result = await this.bend.DataStore.find(BendTable.Tables.COMMUNITY, query, {
      relations: {
        logo: 'BendFile'
      }
    })

    return result
  }

  private fetchLeaderboardList (settings, query): Promise<any> {
    return new Promise((resolve, reject) => {
      query.ascending('rank')
      this.paginateQueryResult(query, settings)

      this.userFind(query, {
        relations: {
            avatar: 'BendFile'
          }
      }).then(res => {
          resolve(res)
        }, (err) => {
            reject(err)
          })
    })
  }

  private fetchListWithQuery<T> (category: string, query, params?): Promise<T> {
    return new Promise((resolve, reject) => {
      this.dataStoreFind(category, query, params).then(res => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  private fetchNewestUsersList (settings, q): Promise<any> {
    return new Promise((resolve, reject) => {
      this.querySortedByCreated2(q)
      this.paginateQueryResult(q, settings)

      this.userFind(q, {
        relations: {
            avatar: 'BendFile',
            community: 'community'
          }
      }).then(res => {
          resolve(res)
        }, (err) => {
            reject(err)
          })
    })
  }

  private fetchUsersList (query): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userFind(query, {
        relations: {
            avatar: 'BendFile',
            community: 'community'
          }
      }).then(res => {
          resolve(res)
        }, (err) => {
            reject(err)
          })
    })
  }

  private fetchEngagements (users): Promise<any> {
    return new Promise((resolve, reject) => {
      const userIds = commonUtil.getIdList(users)
            // get engagements
      const q = new this.bend.Query()
      q.contains('user._id', userIds)
      const aggregation = this.bend.Group.count()
      aggregation.by('user._id')
      aggregation.query(q)
            // get all leaderboard first
      this.dataStoreGroup('activity', aggregation).then(res => {
        const data = users.map(user => {
            const exist = res.find(o => o['user._id'] === user._id)
            return {
                ...user,
                engagements: exist ? exist.result : 0
              }
          })
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }

    /** *** Query settings block *** */

  private defaultQuery () {
    const q = new this.bend.Query()
    q.notEqualTo('deleted', true)
    return q
  }

  private querySortedByName () {
    const q = this.defaultQuery()
    q.ascending('name')
    return q
  }

  private querySortedByCreated () {
    const q = this.defaultQuery()
    q.descending('_bmd.createdAt')
    return q
  }

  private querySortedByCreated2 (q) {
    q.notEqualTo('deleted', true)
    q.descending('_bmd.createdAt')
  }

  private queryByCommunity (communityId) {
    const q = this.defaultQuery()
    q.equalTo('community._id', communityId)
    return q
  }

  private paginateQueryResult (query, settings) {
    query.limit(settings.itemsPerPage)
    query.skip((settings.currentPage - 1) * settings.itemsPerPage)
  }

  private filterQueryByCommunity (query, settings) {
    if (typeof settings.community === 'object' &&
            settings.community._id &&
            settings.community._id.length
        ) {
      query.equalTo('community._id', settings.community._id)
    }
  }

  private filterQueryByCollection (query, settings) {
    const collectionId = _.get(settings, 'collection._id')
    if (collectionId) {
      query.containsAll('collections', [collectionId])
    }
  }

  private filterQueryByCategory (query, settings) {
    if (settings.category && settings.category !== '') {
      if (!settings.category._id) {
        if (settings.category.type === 'group') { query.contains('categories', settings.categoryGroup[settings.category.name]) }
      } else {
        const id = settings.category._id
        query.contains('categories', [id])
      }
    }
  }

  private questionsQuery (settings) {
    const q = this.querySortedByCreated()
        // enable for pagination
        // this.paginateQueryResult(q, settings)

    if (settings.team && settings.team._id && settings.team._id.length) {
      q.contains('teams', [settings.team._id])
    }
    this.filterQueryByCommunity(q, settings)

    return q
  }

  private challengesQuery (settings) {
    const q = this.defaultQuery()
        // this was breaking teams search somehow. i added a sort to the helper
        // this.paginateQueryResult(q, settings)

    if (settings.community._id && settings.community._id !== '') {
      q.equalTo('community._id', settings.community._id)
    }

        // filter results by team
    if (typeof settings === 'object'
            && typeof settings.team === 'object'
            && settings.team._id
            && settings.team._id !== ''
        ) {
      q.contains('teams', [settings.team._id])
    }
        // conversion of JS time to DB time
    const currentTime = Date.now() * 1000000
        // add challenge expiration (if end date is in the past) to query
        // "1" = Expired
        // "2" = Unexpired
        // "" = All
    if (settings.selectedType.value === '1') {
      q.lessThan('endsAt', currentTime)
    } else if (settings.selectedType.value === '2') {
      q.greaterThan('endsAt', currentTime)
    }

    return q
  }

  private eventsQuery (settings) {
    const q = this.defaultQuery()

    if (settings.searchTerm !== '') {
      q.matches('name', settings.searchTerm, {
        ignoreCase: true
      })
    }
    this.filterQueryByCategory(q, settings)
    this.filterQueryByCollection(q, settings)
    this.filterQueryByCommunity(q, settings)
    this.paginateQueryResult(q, settings)
    return q
  }

  private leaderboardQuery (communityAdmin, communityId) {
    const q = this.defaultQuery()
    q.equalTo('enabled', true)
    if (communityAdmin) {
      q.equalTo('community._id', communityId)
    }
    return q
  }

  private updatedUsersQuery (settings) {
    const q = this.usersQuery(settings.searchTerm)
    q.notEqualTo('deleted', true)
    q.descending('_bmd.createdAt')
        // filter results by team
    if (typeof settings === 'object'
            && typeof settings.team === 'object'
            && settings.team._id
            && settings.team._id !== ''
        ) {
      q.contains('teams', [settings.team._id])
    }
    this.paginateQueryResult(q, settings)
    this.filterQueryByCommunity(q, settings)
    return q
  }

  private usersQuery (str) {
    const q = new this.bend.Query()
    if (str === '') return q
    const q2 = new this.bend.Query().matches('name', str, {
            ignoreCase: true
        })
            .or().matches('name', str, {
                ignoreCase: true
            })
            .or().matches('email', str, {
              ignoreCase: true
            })
    return q.and(q2)
  }

  private pushNotifQuery (settings) {
    const q = this.querySortedByCreated()
    this.paginateQueryResult(q, settings)
    if (
            typeof settings.community === 'object' &&
            settings.community._id &&
            settings.community._id.length
        ) {
      q.contains('audience.audiences', [settings.community._id]).or().equalTo('audience.defaultQuery.community_id', settings.community._id)
    }
    return q
  }

  private sortedQuery (settings) {
    const q = this.defaultQuery()
    const user = getActiveUser()
    if (user.communityAdmin) {
      q.equalTo('community._id', user.community._id)
    }

    return q
  }

    /* coincides with usersQuery, remove after users merge, rename query */
  private placesAndUsersQuery (str) {
    const query = new this.bend.Query()
    if (str === '') return query
    const q2 = new this.bend.Query().matches('name', str, {
            ignoreCase: true
        })
            .or().matches('name', str, {
                ignoreCase: true
            })
            .or().matches('email', str, {
              ignoreCase: true
            })
    return query.and(q2)
  }

  private placesQuery (settings) {
    const q = this.placesAndUsersQuery(settings.searchTerm)
    q.notEqualTo('deleted', true)
    q.descending('_bmd.createdAt')
    this.paginateQueryResult(q, settings)
    this.filterQueryByCommunity(q, settings)

    const user = getActiveUser()
    const { communityAdmin } = user
    const userCommunityId = user.community && user.community._id

    if (communityAdmin) {
      q.equalTo('community._id', userCommunityId)
    }

    if (settings.selectedState && settings.selectedState !== 'All States') {
      q.equalTo('state', settings.selectedState)
    }
    if (settings.collectionId) {
      q.contains('collections', [settings.collectionId])
    }
    if (settings.categoryId) {
      q.contains('categories', [settings.categoryId])
    }
    if (settings.category && settings.category !== '') {
      if (!settings.category._id) {
        if (settings.category.type === 'group') {
            q.contains('categories', settings.categoryGroup[settings.category.name])
          }
      } else {
        let id = settings.category._id
        q.contains('categories', [id])
      }
    }

    return q
  }

    /* *** DataStore queries *** */

  private dataStoreFind (category: string, query, params?): Promise<any> {
        // not deleted is in the query before this, but for pushes, it is in an $or statement somehow
    query.notEqualTo('deleted', true)
    return new Promise((resolve, reject) => {
      this.bend.DataStore.find(category, query, params).then((response) => {
        resolve(response)
      })
    })
  }

    // private dataStoreCount (q, category: string, params?) {
    //   return new Promise((resolve, reject) => {
    //     this.bend.DataStore.count(category, q, params).then(res => {
    //       resolve(res)
    //     }, (err) => {
    //       reject(err)
    //     })
    //   })
    // }

  private dataStoreGroup (category: string, aggregation) {
    return this.bend.DataStore.group(category, aggregation)
  }

  private dataStoreSave (category: string, item) {
    return this.bend.DataStore.save(category, item)
  }

  private dataStoreUpdate (category: string, item) {
    return this.bend.DataStore.update(category, item)
  }

  private dataStoreGet (category: string, id) {
    return this.bend.DataStore.get(category, id)
  }

  public getWhitelabel (communityId) {
    let query = new this.bend.Query()
    query.equalTo('community._id', communityId)
    query.notEqualTo('deleted', true)
    return this.bend.DataStore.find('whitelabel', query)
  }
    /* *** User queries *** */

  private userFind (query, params?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bend.User.find(query, params).then((response) => {
        resolve(response)
      })
    })
  }

  public getUserById (id) {
    return new Promise((resolve, reject) => {
      const query = new this.bend.Query()
      query.equalTo('_id', id)
      this.bend.User.find(query).then((response) => {
        resolve(response)
      })
    })
  }

  private userCount (query, params?) {
    return new Promise((resolve, reject) => {
      this.bend.User.count(query).then(res => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  public getUsers (activeUser = getActiveUser()): Promise<List> {
    const q = this.defaultQuery()
    q.equalTo('community._id', activeUser.community._id)
    return this.bend.User.find(q, {})
  }

  public getUsersWithAvatar (activeUser = getActiveUser()): Promise<List> {
    const q = this.defaultQuery()
    q.equalTo('community._id', activeUser.community._id)
    return this.bend.User.find(q, {
      relations: {
        avatar: 'BendFile'
      }
    })
  }

    public getFiles(ids = []): Promise<List> {
        var query = new this.bend.Query()
        query.contains('_id', ids)
        return this.bend.File.find(query)
    }

    public deleteFile(id = null): Promise<List> {
        return this.bend.File.destroy(id)
    }

    /* *** Stuff methods *** */

    // this fn was being called before user data was loaded or sometimes with previously loaded user and returning the wrong info
    // it was using this.user
  private async fetchStatisticsDataWithLogic (user, callback) {
    const startDateOfWeek = commonUtil.getDateOfWeek()[0].getTime() * 1000000
    const isCommunityAdmin = user.communityAdmin
    const communityId = user.community._id
    const data = {
      totalUsers: 0,
      totalActivities: 0,
      totalPoints: 0,
      thisWeekPoints: 0
    }
    parallel([
      (_callback) => {
        const query = this.defaultQuery()

        if (isCommunityAdmin) {
            query.equalTo('community._id', communityId)
          }

        this.bend.User.count(query).then(function (count) {
            data.totalUsers = count
            _callback(null, null)
          }, function (err) {
            _callback(err, null)
          })
      },
      (_callback) => {
        const query = new this.bend.Query()
        query.notEqualTo('deleted', true)
        if (isCommunityAdmin) {
            query.equalTo('community._id', communityId)
          }
        this.bend.DataStore.count('activity', query).then(function (count) {
            data.totalActivities = count
            _callback(null, null)
          }, function (err) {
            _callback(err, null)
          })
      },
      (_callback) => {
        if (isCommunityAdmin) {
            const q = this.defaultQuery()
            q.notEqualTo('deleted', true)
            q.exists('points', true)
            q.equalTo('community._id', communityId)
            let aggregation = this.bend.Group.sum('points')
            aggregation.query(q)
                    // get all leaderboard first
            this.bend.DataStore.group('activity', aggregation).then(function (ret) {
                data.totalPoints = ret.length > 0 ? ret[0].result : 0
                _callback(null, null)
              }, function (err) {
                _callback(err, null)
              })
          } else {
                    //Seba: I don't know why this is here so I'll just leave it
            const q = this.defaultQuery()
            q.exists('points', true)
            const aggregation = this.bend.Group.sum('points')
            aggregation.query(q)
                    // get all leaderboard first
            this.bend.DataStore.group('community', aggregation).then(function (ret) {
                data.totalPoints = ret.length > 0 ? ret[0].result : 0
                _callback(null, null)
              }, function (err) {
                _callback(err, null)
              })
          }
      },
      (_callback) => {
        const q = this.defaultQuery()
        q.exists('points', true)
        q.greaterThan('_bmd.createdAt', startDateOfWeek)
        if (isCommunityAdmin) {
            q.equalTo('community._id', communityId)
          }
        const aggregation = this.bend.Group.sum('points')
        aggregation.query(q)
                // get all leaderboard first
        this.bend.DataStore.group('activity', aggregation).then(function (ret) {
            data.thisWeekPoints = ret.length > 0 ? ret[0].result : 0
            _callback(null, null)
          }, function (err) {
            _callback(err, null)
          })
      }
    ], function (err, rets) {
      callback(err, data)
    })
  }
}

export default new BendClient()
export { BendTable }
