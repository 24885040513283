import * as _ from 'lodash'
import BendClient, { BendTable } from '../features/BendClient'

/**
 * Class that will help with many re usable methods for Bend related actions
 */
class BendHelper {

    private api: any
    private api2: any

    constructor () {
        this.api = BendClient
        this.api2 = BendTable
    }

    /**
     * This method will toggle the enable flag of an item on/off in the database. Used in the lists in the admin
     * 
     * @param e The event that triggered this click
     * @param item The item that we want to toggle
     * @param lib The help lib that will actually call the corresponding update in the table. 
     * 
     * @return {undefined} 
     */
    async enableHelper(e, item, lib){
        
        e.preventDefault();
        e.stopPropagation();

        if (item.enabled === undefined){
            item.enabled = false;
        }

        item.enabled = !item.enabled

        let bItem = await lib.helpers.get(item._id)
       

        if (bItem.enabled === undefined){
            bItem.enabled = false;
        }
    
        bItem.enabled = !bItem.enabled;
        //console.log(bItem)
        
        var bItemClone = _.clone(bItem);
        delete bItemClone.$$hashKey;

        await lib.helpers.update(bItemClone);
    }

    public upload (file, callback, ext, progressCallback) {
        return this.api.upload(file, callback, ext, progressCallback)
    }

    public getFile (refObj, callback) {
        return this.api.getFile(refObj, callback)
    }

    public getFiles (ids = []) {
        return this.api.getFiles(ids)
    }

    public deleteFile (id) {
        return this.api.deleteFile(id)
    }

}
export default new BendHelper()